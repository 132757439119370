import { useEffect, useState } from 'react'
import Icon from './Icon.js'
import styles from '../css/SearchBar.module.css'
import useFetch from '../helpers/useFetch.js'

const SearchBar = ({placeholder}) => {
	placeholder ??= 'Quick search...'

	const [value, setValue] = useState('');

	const searchUrl = value ? `${process.env.REACT_APP_STRAPI_URL}/api/search?search=${encodeURIComponent(value)}`: false// what if value is 0?
	const [isLoading, data, errors, refetch] = useFetch(searchUrl)


	const handleKeyboardInput = (e) => {
		const newValue = e.currentTarget.value;
		setValue(newValue);
	}

	useEffect(() => {
		console.log('data changed');
		console.log(data);
	}, [data])

	return (
		<div className={styles.container}>
			<Icon className={styles.iconSearch} icon='search'/>
			<input className={styles.input} type='text' placeholder={placeholder} value={value} onChange={handleKeyboardInput}></input>
			{value?.length > 0 && <Icon className={styles.iconCross} icon='cross'/>}
			<ul className={styles.resultsList}>
				{isLoading && <li className={styles.result}>Loading...</li>}
				{data?.map(result => {
				return (
					<li key={result.id} className={`${styles.result} `}>
						<a href={`/products/${result.id}`} className={styles.resultInner}>
							<Icon icon='enter' className={styles.iconResult}/>
							<span>{result.name}</span>
						</a>
					</li>);
				})}
			</ul>
		</div>
	)
}

export default SearchBar	