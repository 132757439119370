import { useContext, useEffect, useState } from 'react'
import useFetch from './useFetch';
import { AuthStateContext } from './authContext';



export const useCart = (loginData) => {
	const [clientCartItems, setClientCartItems] = useState([]);
	const {userData, jwt} = useContext(AuthStateContext);

	const addCartItemsClientSide = (title,size, colorName, amount, imagePath, basePrice, baseDiscount, priceDropQuantity, priceAfterPriceDrop) => {

		// check if no overlapping?
		console.log('Tried to add items client side. Must be implemented!!!')
	}
	
	const url = `${process.env.REACT_APP_STRAPI_URL}/api/cart_items/user_find`;
	const [isLoading, cartItems, error, refetch, refetchCount] = useFetch(url, ['Public']);

	useEffect(() => {
		// get all cart items for user
		if(cartItems){
			const copy = cartItems.map(cartItem => ({...cartItem, isDirty: false}))
			setClientCartItems(copy);
		}
	},[refetchCount, cartItems])

	const changeCartItemsClient = (id, changeObject) => {

		const copy = {...clientCartItems[id]};
		if(copy) {
			Object.entries(changeObject).forEach(change => {
				const [changeKey, changeValue] = change;
				if(copy[changeKey] !== changeValue) {

					copy[changeKey] = changeValue;
					copy.isDirty = true;
				}
			});

			setClientCartItems(oldItems => {
				const arrayCopy = [...oldItems];
				arrayCopy[id] = copy
				return arrayCopy;
			});
		}
	}

	const updateAmountServer = async (cartItemId, newAmount) => {
		const index = clientCartItems.findIndex(findEl => findEl.id === cartItemId);
		
		let cartItemsCopy = [...clientCartItems];
		cartItemsCopy[index] = {...cartItemsCopy[index], amount: newAmount, isLoading: true};
		setClientCartItems(cartItemsCopy);

		const url = `${process.env.REACT_APP_STRAPI_URL}/api/cart_items/user_updateOne/${cartItemId}`;
		const response = await fetch(url, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${jwt}`,
				"Content-Type" : "application/json",
			},
			body: JSON.stringify({amount: newAmount})
		});
		console.log('hEREEEEEeeeee')
		if(response.ok) {
			const result = await response.json();
			console.log({result})
			if(result?.action === 'deleted') {
				refetch();
			} else {
				const cartItemsCopy2 = [...cartItemsCopy];
				cartItemsCopy2[index] = {...cartItemsCopy[index], isLoading: false, isDirty: false};
				setClientCartItems(cartItemsCopy2);
			}
		}
		return response;
	}

	return [clientCartItems, refetch, addCartItemsClientSide, changeCartItemsClient, updateAmountServer];
}