import useFetch from '../helpers/useFetch.js';
import {Link} from 'react-router-dom';
import ProductGalleryItem from './ProductGalleryItem';

import styles from '../css/ProductGallery.module.css';

import { historyKey, getAllUniqueColors, getDefaultVariation, missingImageUrl, getAllUniqueSizes, prependServerURL} from '../helpers/helper.js';
import usePost from '../helpers/usePost.js';
import { useMemo } from 'react';

// Input for querytype: 
// 'featured'
// 'new'
// 'view history'
// 'category:XXX'
const ProductGallery = ({title, queryType, products, bHideEmpty, loginData, className, small, excludedProductIds}) => {
	excludedProductIds ??= [];
	className ??= '';
	bHideEmpty = bHideEmpty ?? true;

	
	const url = `${process.env.REACT_APP_STRAPI_URL}/api/products/customFind`;
	const bodyMemo = useMemo(() => {
		if(!queryType) {
			console.log('>> products <<');
			console.log(products)
			return false;
		}

		let queryBody = {};
		queryBody.limit = 10;
		switch (queryType.toLowerCase()) {
			case 'featured':
				queryBody.featured = true;
				break;
			case 'new':
				break;
			case 'recently visited':
				const viewHistoryIdsJson = window.localStorage.getItem(historyKey);
				if(viewHistoryIdsJson) {
					const viewHistoryIds = JSON.parse(viewHistoryIdsJson);
					queryBody.productIds = viewHistoryIds;
				}
				break;
			default:
				const prefix = 'category:';
				if(queryType?.substring(0,prefix.length) === prefix) {
					const category = queryType.substring(prefix.length);
					queryBody.categoryNames = [category];
				}
				break;
		}
		return queryBody;
	}, [queryType]);
	const [isLoading, data, errors] = usePost(url, bodyMemo);


	const createItemsJsx = (dataToUse) => {

		if(!dataToUse) {
			return;
		}
		console.log(dataToUse)
		const validProducts = (Array.isArray(dataToUse) ? dataToUse : []).filter(product => product.variations.length > 0)
		return validProducts.map(product => {

			const defaultVariation = getDefaultVariation(product.variations);
			const allColors = getAllUniqueColors(product.variations);
			const allSizes = getAllUniqueSizes(product.variations);

			let thumbnailImageUrl = false;
			if(product.thumbnailImage !== null) {
				thumbnailImageUrl = prependServerURL(product.thumbnailImage.url,true,false);
			} else {
				thumbnailImageUrl = missingImageUrl;
			}

			return (
			<li key={product.id}>
				<Link to={`/products/${product.id}`} style={{textDecoration: 'none'}}>
				<ProductGalleryItem
					title={product.name}
					basePrice={defaultVariation?.basePrice}
					baseDiscount={defaultVariation?.baseDiscount}
					priceDropQuantity={defaultVariation?.priceDropQuantity}
					priceAfterPriceDrop={defaultVariation?.priceAfterPriceDrop}
					minimumAmount={defaultVariation.minimumAmount}
					imageUrl={thumbnailImageUrl}
					soldPer={product.soldInPacksOf}
					uniqueColors={allColors}
					uniqueSizes={allSizes}
					loginData={loginData}
				/>
				</Link>
			</li>);
		});
	};

	let listItemsJsx = [];
	if(products !== undefined) {
		console.log({products});
		listItemsJsx = createItemsJsx(products);
	} else if (data) {
		listItemsJsx = createItemsJsx(data);
	}else {
		
		console.log('HIT@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
	}

	const bShouldRender = bHideEmpty === false || !(bHideEmpty && listItemsJsx.length === 0);
	return ( <> { bShouldRender && 
		<section className={className}>
			<h2 className={styles.title}>{title}</h2>
			<ul className={`${styles.gallery} ${small ? styles.gallerySmall : ''}`}>
				{listItemsJsx}
			</ul>
		</section>
	} </>
		
	)


}

export default  ProductGallery