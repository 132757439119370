import { useState } from 'react';
import styles from '../css/inputs.module.css';

const InputCheckbox = ({children, value, name, error, onChange, disabled, defaultValue}) => {
	error ??= '';
	defaultValue ??= false;

	const [checked, setChecked] = useState(defaultValue);
	const handleOnChanged = (e) => {
		setChecked(e.target.checked);
		onChange(e);
	};
	
	return (
		<div className={`${styles.checkboxContainer} ${error ? styles.hasError : ''}`}>
			<label className={`${styles.checkboxWrapper} `} htmlFor={name}>
				<input disabled={disabled} checked={checked} style={{display: 'none'}} type='checkbox' id={name} name={name} onChange={handleOnChanged}/>
				<div className={styles.visualCheckbox}></div>
				<div className={styles.checkboxLabel}>{children}</div>
			</label>
			<span className={styles.errorMessage}>{error}</span>
		</div>
	);

}
export default InputCheckbox;