import { useState } from 'react';
import styles from '../css/inputs.module.css'


const InputTextUpdateField = ({state, name, isRequired, label, clientValue, error, saved, pattern, triggerOnChange, triggerOnSubmit, triggerOnFocus, triggerOnBlur }) => {

	const stateData = {
		inactive : {
			className: styles.updateFieldInactive,
			buttonText: 'Edit'
		},
		editing : {
			className: styles.updateFieldEditing,
			buttonText: 'Update'
		},
		updating : {
			className: styles.updateFieldUpdating,
			buttonText: 'Loading...'
		},
		updated : {
			className: styles.updateFieldUpdated,
			buttonText: 'Updated'
		}
	}

	const handleOnClick = (e) => {
		//e.preventDefault();
		if(e.target.getAttribute('value') === stateData.inactive.buttonText) {
			// select other input
			// implement later
		}
	}

	return (
		<div className={`${styles.updateFieldForm} ${error ? styles.hasError : ''} ${stateData[state].className}`}>  
			<label className={styles.label}>{`${label}${isRequired ? '*' : ''}`}</label>
			<form className={styles.updateFieldForm} onSubmit={(e) => {e.preventDefault(); triggerOnSubmit(e)}}>
				<input 
					type="text"
					disabled={state === 'updating'} 
					className={`${styles.updateFieldInputText}`} 
					name={name} 
					value={clientValue}
					pattern={pattern}
					onChange={triggerOnChange}
					onFocus={triggerOnFocus}
					onBlur={triggerOnBlur}
				/>
				<input type="submit" onClick={e => handleOnClick(e)} className={styles.updateFieldButton} value={stateData[state].buttonText}/>
			</form>
			<span className={`${styles.message} ${styles.errorMessage}`}>{error}</span>
		</div>
	);
}

export default InputTextUpdateField;