import Page from './Page.js';
import SignUpStep1 from './SignUpStep1.js';
import SignUpStep2 from './SignUpStep2.js';
import SignUpStep3 from './SignUpStep3.js';
import styles from '../css/SignUpPage.module.css';
import { useState, useRef, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SignUpStep4 from './SignUpStep4.js';
import useFetch from '../helpers/useFetch.js';
import { sleep } from '../helpers/helper.js';
import { AuthStateContext } from '../helpers/authContext.js';

const SignUpPage = () => {	
	const {login} = useContext(AuthStateContext);
	const step1 = useRef();
	const step2 = useRef();
	const step3 = useRef();
	const step4 = useRef();
	const steps = [step1, step2, step3, step4];

	const header = useRef();
	const fieldsRef = useRef([]);
	const [doHeightTransition, setDoHeightTransition] = useState(false);
	const [step, setStep] = useState(1);
	const [targetCardDimensions, setTargetCardDimensions] = useState({height: '0px'});
	const [validations, setValidations] = useState({});
	const navigate = useNavigate();

	const [isLoading, countries] = useFetch(`${process.env.REACT_APP_STRAPI_URL}/api/countries`);
	
	useEffect(() => {
		const headerHeight = header.current.offsetHeight;
		const stepContentHeight = steps[step-1].current.offsetHeight;
		setTargetCardDimensions({height: headerHeight + stepContentHeight});
	}, [step]);

	const updateStep = (newStep) => {
		setDoHeightTransition(true);
		const creatUserConditions = [
			() => {
				return newStep > 4
			},
			() => {
				const result = step === 3 && newStep === 4 && fieldsRef.current[2].hasOwnProperty('isCompanyAddressSameAsShippingAddress');
				if(result) {
					fieldsRef.current[2].isCompanyAddressSameAsShippingAddress = true; // because server wants a bolean as value, not a string. hacky
				}
				return result
			}
		];
		if(creatUserConditions.reduce((acc, current) => acc || current(), false)) {
			createUser();
			return;
		} 
		setStep(Math.max(0,newStep));
	}
	
	
	// Data is an object where the propertyKey = the name of the field, and the propertyValue = value to validate
	// I need typescript....
	const serverValidate = async (stepNumber, data) => {
		const url = `${process.env.REACT_APP_STRAPI_URL}/api/users-permissions/signUpValidationStep${stepNumber}`;
		const response = await fetch(url, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({data})
		});

		const result = await response.json();
		setValidations(result);

		// Check if all ok => next step + keep values
		const allOk = Object.entries(result).reduce((prev, currentResult) => prev && currentResult[1] === true, true);
		if(allOk) {
			fieldsRef.current[stepNumber - 1] = {...data};
			updateStep(step + 1);
		}

		return true;
	}

	const createUser = async () => {
		const url = `${process.env.REACT_APP_STRAPI_URL}/api/users`;
		const response = await fetch( url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({data: fieldsRef.current})	
		});

		const result = await response.json();
		if(result?.action === 'reload') {
			window.location.reload(false);
		} else if ( result?.action === 'login') {
			navigate('/pleaseconfirmemail');
		} else {
			console.log("CREATE USER WENT WRONG>>>");
		}
		
	}

	const resetValidation = (event) => {
		console.log('reset :)')
		const name = event.target.getAttribute('name');
		const newValidations = {...validations};
		delete newValidations[name];
		setValidations(newValidations);
	}

	const getErrorJsx = (fieldName) => {
		if(validations.hasOwnProperty(fieldName)) {
			const validationObject = validations[fieldName];
			if(typeof validationObject === 'string') {
				return validationObject;
			} else {
				return ''
			}
		}
		return '';
	};


	// ___________________________________________________ JSX Helper
	const getClassNames = (componentStep) => {
		if(componentStep === step - 1) {
			return `${styles.out}`;
		} else if ( componentStep === step) {
			return `${styles.focus}`;
		} else if ( componentStep === step + 1) {
			return `${styles.in}`;
		}
		return `${styles.hidden}`;
	};

	
	let cardStyle = {
		minHeight: targetCardDimensions.height
	};

	if(!doHeightTransition) {
		cardStyle.transitionDuration = '0ms';
	}

	
	const countriesSafe = countries ? countries.data : [{attributes:{name: 'Belgium', abbreviation: 'BE', flagEmoji: 'be'}}]
	console.log({countriesSafe});
	const countryOptions = countriesSafe.map(country => {const id = country.id; country = country.attributes; return {value: `${id}`, display: `${country.flagEmoji} ${country.name}`, disabled: false}})
	console.log({countryOptions});

	return (
	<Page bHasNavBar={false} pageWidth='450px' className={styles.cardWrapper}>
		<div className={styles.card} style={cardStyle}>
			<header className={styles.header} ref={header}>
				<Link className={styles.left} to="/">
					<span className={styles.belpo}>Belpo</span>
					<span className={styles.b2b}>B2b portal</span>
				</Link>
				<Link to='/signin'className={styles.right}>
					Already have <br/> an account? <br/>
					<strong>Sign in</strong>
				</Link>
			{step > 1 && <span className={styles.back} onClick={() => updateStep(step - 1)}>&#60; Back</span>}
			</header>
			<SignUpStep1 className={`${getClassNames(1)} ${styles.signUpStep}`}  triggerSubmit={(data) => serverValidate(1, data)} errors={validations} getErrorJsx={getErrorJsx} reference={step1} resetSingleValidation={resetValidation} disabled={step !== 1}/>
			<SignUpStep2 className={`${getClassNames(2)} ${styles.signUpStep}`}  triggerSubmit={(data) => serverValidate(2, data)} errors={validations} getErrorJsx={getErrorJsx} reference={step2} resetSingleValidation={resetValidation} disabled={step !== 2}/>
			<SignUpStep3 className={`${getClassNames(3)} ${styles.signUpStep}`}  triggerSubmit={(data) => serverValidate(3, data)} errors={validations} getErrorJsx={getErrorJsx} reference={step3} resetSingleValidation={resetValidation} disabled={step !== 3} countryOptions={countryOptions}/>
			<SignUpStep4 className={`${getClassNames(4)} ${styles.signUpStep}`}  triggerSubmit={(data) => serverValidate(4, data)} errors={validations} getErrorJsx={getErrorJsx} reference={step4} resetSingleValidation={resetValidation} disabled={step !== 4} countryOptions={countryOptions}/>
		</div>
	</Page>
	);
}

export default SignUpPage;