import styles from '../css/CategoryThumbnail.module.css';

const CategoryThumbnail = ({title, thumbnailUrl, isLoadingPlaceholder}) => {
	isLoadingPlaceholder = isLoadingPlaceholder ?? false;
	if(isLoadingPlaceholder) {
		title = 'category...';
	}

	// must link to the category
	return (
	<article className={styles.container}>
		<div className={styles.imageWrapper}>
			{isLoadingPlaceholder && <div className={styles.image} style={{backgroundColor: 'gray'}}/>}
			{!isLoadingPlaceholder && <img className={styles.image} src={thumbnailUrl}/>}
		</div>
		<header className={styles.titleWrapper}>
			<h3 className={styles.title}>{title}</h3>
		</header>
	</article>
	);
}

export default CategoryThumbnail