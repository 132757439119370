import styles from '../css/OrderHistory.module.css'
import { calculateFinalPrice, formatCurrency, formatStrapiDate, prependServerURL } from '../helpers/helper';
import GeneralButton from './GeneralButton';

// NEXT:  orderItem must have a relational field to the variation (a dynamiczone)


const OrderHistoryRow = ({readableDate, orderId, orderItems, orderStatusId, orderStatusDisplay, orderTrackingNumber, orderTrackingLink}) => {


	const calculateTotalPrice = (orderItems) => {
		let sum = 0;
		orderItems.forEach(item => sum += calculateFinalPrice(item.basePriceAtTimeOfOrder, item.baseDiscountAtTimeOfOrder, item.amount, item.priceDropQuantityAtTimeOfOrder, item.priceAfterPriceDropAtTimeOfOrder).finalPrice)
		return sum;
	}


	const getOrderItemRow = (orderItem) => {
		return <li>
			<a className={styles.itemOuter}href={`/products/${orderItem.product.id}`}>
				<div className={styles.itemInner} >
					<div className={styles.itemImageWrapper}>
						<img className={styles.itemImage} src={prependServerURL(orderItem.imageUrl)} alt={orderItem?.readableOrderItemName}/>
					</div>
					<div className={styles.itemContent}>
						<p className={styles.itemTitle}>{orderItem.amount}x {orderItem.product.name}</p>
						{Object.entries(orderItem.variationData).length > 0 && 
						<ul className={styles.variationDatas}>
							{Object.entries(orderItem.variationData).map(([key, value]) => <li className={styles.variationData}>
								<span>{key === 'colorName' ? 'color' : key}: {value}</span>
							</li>)}
						</ul>
						}
						<GeneralButton className={styles.itemButton} colorVariant='blackOutline' to={`/products/${orderItem.product.id}`}>View product</GeneralButton>
				</div>
				</div>

			</a>
		</li>
	}

	return (
		<article className={styles.orderContainer}>
			<header className={styles.header}>
				<div className={styles.headerItem}>
					<span className={styles.headerItemLabel}>Order placed</span>
					<strong><span>{readableDate}</span></strong>
				</div>
				<div className={styles.headerItem}>
					<span className={styles.headerItemLabel}>total</span>
					<strong><span>{formatCurrency(calculateTotalPrice(orderItems))}</span></strong>
				</div>
				<div className={styles.headerItem}>
					<span className={styles.headerItemLabel}>status</span>
					{orderTrackingLink !== null && 
						<strong><a href={orderTrackingLink} className={styles.orderStatusLink}>{orderStatusDisplay}</a></strong>
					}
					{orderTrackingLink === null && 
						<strong><span>{orderStatusDisplay}</span></strong>
					}
				</div>
			</header>
			<div>
				{
					orderItems.map(orderItem => getOrderItemRow(orderItem))
				}
			</div>
		</article>
	)

}

export const OrderHistory = ({isLoading, orders}) => {
	orders ??= [];

	console.log(orders)

	if(isLoading) {
		return (
			<div className={styles.orderTabMessageWrapper}>
					<span className={styles.orderTabMessage}>Loading...</span>
			</div>
		);
	}


	return (
		<section>
			<h2 className={styles.panelTitle}>Order history</h2>
			{orders.length < 1 && 
				<div className={styles.orderTabMessageWrapper}>
					<span className={styles.orderTabMessage}>You haven't placed an order yet...</span>
					<GeneralButton to='/'>Browse products</GeneralButton>
				</div>
			} 
			{orders.length > 0 && 
				<ul className={styles.ordersList}>	
					{orders.map(
						(order, index) => (
						<li>
							<OrderHistoryRow
								readableDate={formatStrapiDate(order.createdAt)} 
								orderId={index + 1}
								orderItems={order.orderItems}
								orderStatusDisplay={order.deliveryStatusDisplay}
								orderStatusId={order.deliveryStatusId}
								orderTrackingNumber={order.deliveryTrackingNumber}
								orderTrackingLink={order.deliveryTrackingNumber}
								/>
						</li>
					))}
				</ul>
			}
		</section>

	)
}