import { useNavigate } from 'react-router-dom'
import styles from '../css/HiddenPrices.module.css'
const HiddenPrice = ({ className, isLoggedIn, short}) => {

	const navigate = useNavigate();
	const onClickLink = (e) => {
		e.preventDefault();
		e.stopPropagation();
		navigate('/signUp');
	}

	if(isLoggedIn) {
		return (
			<div className={`${styles.priceReplacementWrapper} ${className}`}>
				{!short && <span className={styles.looking}>Looking for a price?</span>}
				{short && <span className={styles.small}>Price hidden.</span>}
				<span className={`${styles.priceReplacement}`}>{short ? '€ ... ' : 'Your account has net been approved by Belpo yet.'}</span>
				{!short && <span className={`${styles.priceReplacement}`}>Your approval will be updated shortly.</span>}
			</div>
		);

	} else 
	{
		return <p className={`${className} ${styles.priceReplacementWrapper}`}><span onClick={onClickLink} className={styles.link}>Sign up</span> to see prices...</p>
	}
	
}

export default HiddenPrice;