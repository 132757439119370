import Page from './Page'
import styles from '../css/AccountPage.module.css';
import { useContext, useEffect, useState } from 'react';
import AccountCompanyInfo from './AccountCompanyInfo';
import AccountShippingInfo from './AccountShippingInfo';
import { getUserAvatarSrc } from '../helpers/helper';
import useFetch from '../helpers/useFetch.js';
import { OrderHistory } from './OrderHistory';
import DownloadModal from './DownloadModal';
import GeneralButton from './GeneralButton';
import {AuthStateContext} from '../helpers/authContext';

const AccountPage = () => {
	const {userData, logout} = useContext(AuthStateContext); 
	console.log({userData})
	const params = new URLSearchParams(window.location.search);
	const [isLoading, countriesData] = useFetch(`${process.env.REACT_APP_STRAPI_URL}/api/countries`);
	const countriesSafe = countriesData ? countriesData.data : [{attributes:{name: 'Belgium', abbreviation: 'BE', flagEmoji: 'be'}}]
	const countryOptions = countriesSafe.map(country => {const id = country.id; country = country.attributes; return {value: `${id}`, display: `${country.flagEmoji} ${country.name}`, disabled: false}})
	const [isLoadingOrders, orders] = useFetch(`${process.env.REACT_APP_STRAPI_URL}/api/order/user_find`);
	
	const tabs = [
		{
			label: 'Order history',
			id: 'history'
		}, {
			label: 'Company details',
			id: 'company'
		}, {
			label: 'Shipping details',
			id: 'shipping'
		}
	];

	const paramTabId = tabs.findIndex(tab => tab.id === params.get('tab'));
	const [activeTabId, setActiveTabId] = useState( paramTabId > - 1 ? paramTabId :  0);
	
	const tabsJsx = tabs.map((tab, index) => {
		return (
			<li key={tab.label}><span onClick={() => setActiveTabId(index)}className={`${activeTabId === index ? styles.tabActive : ''} ${styles.tab}`}>{tab.label}</span></li>
		)
	});

	return (
		<Page pageWidth="960px" bHasNavBar={true} contentClassName={styles.page}>
			<header className={`${styles.header}`} style={{display: 'none'}}>
				<h1 className={styles.title}>My account</h1>
			</header>

			<section className={`${styles.navWrapper} ${styles.card}`}>
				<ul className={styles.nav}>
					{tabsJsx}
				</ul>
			</section>

			<section className={`${styles.left} ${styles.card}`}>
				<div className={styles.companyDetails}>
					<div className={styles.companyDetailsImageWrapper}>
						<div>
							<img className={styles.profilePicture} src={getUserAvatarSrc(userData?.companyName)}></img>
						</div>
					</div>
					<div className={styles.companyDetailsInformation}>
						<h2 className={styles.companyCardTitle}>{userData.companyName ?? 'loading'}</h2>
						<span>{userData?.email ?? 'loading'}</span>
						<span className={styles.location}>{userData?.companyCity ?? 'loading'}, {userData?.companyCountry?.name ?? 'loading'}</span>
						<span style={{textAlign: 'center'}}>{userData?.approvalStatus ?? 'loading'}</span>
					</div>
					<GeneralButton colorVariant='blackOutline' onClick={logout}>Logout</GeneralButton>
				</div>

			</section>
			<section className={`${styles.tabs} ${styles.card}`}>
				{activeTabId === 0 ? <OrderHistory isLoading={isLoadingOrders} orders={orders}></OrderHistory>: ''}
				{activeTabId === 1 ? <AccountCompanyInfo  countryOptions={countryOptions}/> : ''}
				{activeTabId === 2 ? <AccountShippingInfo  countryOptions={countryOptions}/>: ''}
				
			</section>
			{/* <DownloadModal loginData={loginData}/> */}
		</Page>
	)
}

export default AccountPage;