import Page from './Page'
import styles from '../css/ResetPasswordPage.module.css'
import { Link, useNavigate } from 'react-router-dom'
import InputText from './InputText'
import GeneralButton from './GeneralButton'
import { useEffect, useRef, useState } from 'react'
import { dataFromForm, getErrorFromErrorsState, removeFieldError } from '../helpers/helper'

const getCode = () => {
	// get URL 
	// Get search params
	const params = new URL(document.location).searchParams;
	return params.get('code');
}

const validateForm = (formData) => {
	// not the same
	if(!formData.password) {
		return {ok: false, errors: {'password': 'Required field'}}
	}
	if(!formData.passwordConfirmation) {
		return {ok: false, errors: {'passwordConfirmation': 'Required field'}}
	}

	return formData.password === formData.passwordConfirmation ? {ok: true, errors: {}} : {ok: false, errors:{'passwordConfirmation': 'Passwords do not match'}}
	// Regex (length, nubmers etc is done on server)
} 


export const ResetPasswordPage = ({loginWithJwt}) => {
	const code = getCode();
	const navigate = useNavigate()
	useEffect(() => {
		if(code === null) {
			// redirect to sign in
			setErrors({generalError: 'Your unique resetpassword-code is missing.\n Please use the link we send you through email\nor request another password reset.'});
		}
	},[])

	const submit = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	
	const handleOnSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const data = dataFromForm(e.target);

		const clientsideErrors = validateForm(data);
		if(!clientsideErrors.ok) {
			console.log({clientsideErrors});
			setErrors(clientsideErrors.errors)
			setIsLoading(false);
			return;
		}

		console.log({data})
		const url = `${process.env.REACT_APP_STRAPI_URL}/api/auth/reset-password`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({code, password: data.password, passwordConfirmation: data.passwordConfirmation})
		});

		const result = await response.json();
		console.log({response});
		console.log({result});

		setIsLoading(false);
		if(!response.ok || !result.hasOwnProperty('jwt')) {
			if(result.error.message === 'Incorrect code provided') {
				setErrors({generalError: 'Your unique resetpassword-code is invalid. Maybe this code has already been used.'})
			} else {
				setErrors({'password': result.error.message});
			}
		} else {
			loginWithJwt(result.jwt)
		}
	}



	return <Page bHasNavBar={false} pageWidth='450px' contentClassName={styles.card}>
		<header className={styles.header}>
				<div className={styles.left}>
					<span className={styles.belpo}>Belpo</span>
					<span className={styles.b2b}>B2b portal</span>
				</div>
				<Link to='/signin'className={styles.right}>
					<span>Back to Sign In</span>
				</Link>
				<h1 className={styles.title}>Reset Password</h1>
			</header>
			{errors.hasOwnProperty('generalError') && <div className={styles.errorWrapper}>
					<p className={styles.errorEmoji}>🔒</p>
					<p className={styles.errorMessage}>{errors.generalError}</p>				
					<Link to='/forgotpassword'><p className={styles.link} >Reset password again</p></Link>
					<Link to='/signin'><p className={styles.link}>Back to sign in</p></Link>
				</div>}

			{!errors.hasOwnProperty('generalError') && 
				<form onSubmit={handleOnSubmit}>
					<InputText type='password' name='password' onFocus={() => removeFieldError(errors,setErrors, 'password')} error={getErrorFromErrorsState(errors,'password')}>New password</InputText>
					<InputText type='password' name='passwordConfirmation' onFocus={() => removeFieldError(errors,setErrors, 'passwordConfirmation')} error={getErrorFromErrorsState(errors,'passwordConfirmation')}>Confirm new password</InputText>
					<GeneralButton onClick={() => submit.current.click()} size={1} isLoading={isLoading}>Reset &amp; login</GeneralButton>
					<input ref={submit} type='submit' style={{display: 'none'}}></input>
				</form>
			}
	</Page>
}
