import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthStateContext } from './authContext';


// url = string
// body = object
const usePost = (url, body,  blockRequest) => {


	const {jwt, logout} = useContext(AuthStateContext);
	blockRequest ??= false;
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);
	const [errors, setErrors] = useState([]);

	useEffect(() => {

		if(url === false || body === false || blockRequest === true) 
			return;

		const asyncPost = async (myUrl, myBody) => {
			setIsLoading(true);
		
			let headers = {"Content-Type": "application/json"}
			if(jwt) {
				headers.Authorization= `Bearer ${jwt}`;
			}

			const options = {
				method:	'POST',
				credentials: 'include',
				headers,
				body: JSON.stringify({data: body})
			};

			const response = await fetch(myUrl, options);
			const myData = await response.json();

			// check if ok -> how?

			if(response.ok === false) {
				// something went wrong
				// update errors
				logout();
			} else {
				setData(myData);
				setIsLoading(false);
			}
		}

		asyncPost(url, data);
	},[url, body]);

	return [isLoading, data, errors];
}

export default usePost;