import styles from '../css/GeneralNumberField.module.css';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { type } from '@testing-library/user-event/dist/type';

const GeneralNumberFieldUncontrolled = forwardRef(({disabled, className, value, name, defaultValue, min, max, step, onChange, onEnter, onTextInputFinish, onClickInputFinish}, ref) => {
	step ??= 1;
	max ??= 9999;
	min ??= 1;
	defaultValue ??= 1;
	disabled ??= false;
	value ??= defaultValue;



	const inputTypeRef = useRef({textInput: false, buttonClicks: false});
	const [originalValue, setOriginalValue] = useState(min);
	// type 1: textInput
	// type 2: buttonClicks
	// type 0: inactive (='')
	const textInputFinishRef = useRef(null);
	textInputFinishRef.current = onTextInputFinish;

	const clickInputFinishRef = useRef(null);
	clickInputFinishRef.current = onClickInputFinish
	const plusButtonRef = useRef(null);
	const minButtonRef = useRef(null);
	

	useEffect(() => {
		if(!onClickInputFinish) {
			return;
		}

		const upToDateClickInputFinish = () => {
			console.log('Mouse leave button');
			if(inputTypeRef.current.textInput === false && !disabled) {
				clickInputFinishRef.current();
			}
		}

		
		const todo = [plusButtonRef, minButtonRef];

		const shouldAttach = plusButtonRef.current && minButtonRef.current;
		if(!shouldAttach)  {
			console.log('aborted');
			return; 
		}

		todo.forEach(buttonRef => {
			if(buttonRef.current) {
				buttonRef.current.addEventListener('mouseleave',upToDateClickInputFinish);
			}
		});

		console.log('attached');

		return () => {
			todo.forEach(buttonRef => {
				if(buttonRef.current) {
					buttonRef.current.removeEventListener('mouseleave',upToDateClickInputFinish);
				}
			});
			console.log('detached');
		}
	}, [plusButtonRef.current, minButtonRef.current])

	const validateCurrentValue = () => {
		let newCount = parseInt(valueWhileTyping);
		if(isNaN(newCount)) {
			console.log('typed value was not a number -> default to original vlaue')
			newCount = originalValue;
		}

		newCount = newCount < min ? min : newCount > max ? max : newCount;
		console.log(`validated count: ${newCount}`);
		onChange(newCount);
		console.log('validate')
		return newCount;
	}

	const updateCount = (number) => {

		if (disabled) {
			return;
		}

		if(typeof number === 'number') {
			number = number.toString();
		}
		// At this point number is always a string
		number = number.replace(/^0*(?=[^0])/, '');
		console.log(`Update count: ${number}`);
		onChange(number);
	}
	// Update count if out of bounds.
	if(value < min || value > max) {
		const clampedValue = value < min ? min : value > max ? max : value;
		updateCount(clampedValue);
	}



	const handleOnChange = (newValue) => {
		if(isTyping) {
			console.log('typing ' + newValue);
			setValueWhileTyping(newValue);

			//updateCount(newValue);
			return;
		}
	}

	const handleOnKeyDown = (e) => {
		if(e.key === 'Enter') {
			e.target.blur();
		}
	};

	// should have triggers for 'onTypeInputLeave' -> leaves on onBlur (and enter -> How is enter detected? does it blur aswell?? )
	// should have trigger for 'onMouseInputLeave' -> When input::text not focused && mouse leaves


	const selectFieldContent = (e) => {
		setValueWhileTyping(value);
		e.target.focus();
		e.target.select();
		inputTypeRef.current = {...inputTypeRef.current, textInput: true};
		setIsTyping(true);
		setOriginalValue(value);
		console.log('Text input: true')
	}
	
	const handleOnBlur = (e) => {
		inputTypeRef.current = {...inputTypeRef.current, textInput: false};
		setIsTyping(false);
		const validatedValue = validateCurrentValue();
		console.log('onblur');
		if(onTextInputFinish) {
			onTextInputFinish(validatedValue);
		} else {
			
			console.log(`input on detail page: ${validatedValue}`)
			onChange(validatedValue);
		}
	}

	const [valueWhileTyping, setValueWhileTyping] = useState(min);
	const [isTyping, setIsTyping] = useState(false);

	return (
		<div className={`${styles.container} ${className} ${disabled ? styles.disabled : ''}`} ref={ref}>
			<div className={styles.min} onClick={() => updateCount(Math.max(parseInt(value) - step, min))} ref={minButtonRef}>-</div>
			<input className={styles.number} type='number' name={name} value={isTyping ? valueWhileTyping : value} disabled={disabled} onChange={(e) => handleOnChange(e.target.value)} onKeyDown={handleOnKeyDown} onBlur={handleOnBlur} onFocus={selectFieldContent}/>
			<div className={styles.plus} onClick={() => updateCount(Math.min(parseInt(value) + step, max))} ref={plusButtonRef}>+</div>
		</div>
	)

})

export default GeneralNumberFieldUncontrolled;