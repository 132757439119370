import { NavLink, Link} from 'react-router-dom';
import { useContext, useState } from 'react';
import styles from '../css/NavBar.module.css';
import { historyKey, isApproved, prependServerURL } from '../helpers/helper';
import GeneralButton from './GeneralButton.js';
import { CartContext } from '../helpers/useCartContext';
import SearchBar from './SearchBar';
import useFetch from '../helpers/useFetch';
import DownloadModal from './DownloadModal';
import Icon from './Icon';
import { AuthStateContext } from '../helpers/authContext';

const ResetViewHistoryStorage = () => {


	const viewHistoryJson = window.localStorage.getItem(historyKey);
	const ids = viewHistoryJson ? JSON.parse(viewHistoryJson) : [];
	const isHistoryEmpty = (ids).length === 0;
	let value = isHistoryEmpty ? 'empty...' : `reset (${ids.length})`;

	const handleOnClick = () => {
		window.localStorage.removeItem(historyKey);
		window.location.reload(false);
	}
	return <button onClick={handleOnClick}>{value}</button>
}


const NavBar = () => {
	const {userData } = useContext(AuthStateContext);
	const downloadFetchURL = `${process.env.REACT_APP_STRAPI_URL}/api/download-items`;
	const [isLoadingDownloads, downloadsData, downloadsError] = useFetch(downloadFetchURL, ['Public'] );
	const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);

	const {cartItems} = useContext(CartContext);
	let count = 0;
	const countIsAmounts = true;

	if(countIsAmounts && cartItems?.length > 0 ) {
		count = cartItems.reduce( (acc, current) => {
			return acc + parseInt(current.amount)}, 0);
	}

	const isLoggedIn = !!userData;
	const assignStyling = ({isActive, isPending}) => {

		return isPending ? styles.pending : isActive ? styles.active : '';
	};

	const leftJsx = (
		<header className={styles.left}>
				<span className={styles.belpoWrapper}>
					<Link to='/' style={{textDecoration:'none', color:'inherit'}}><h1 className={styles.belpo}>Belpo</h1></Link>
					<span className={`${styles.b2b} ${styles.desktop}`}>B2B</span>
				</span>
			</header>
	);

	const signedInJsx = (
		<>	
			{leftJsx}
			<SearchBar/>
			<div className={styles.right}>
				
				<Icon icon='downloadFolder' className={`${styles.mobile} ${styles.whiteIcon} ${styles.bigIcon}`}/>
				<Icon icon='account' className={`${styles.mobile} ${styles.whiteIcon} ${styles.bigIcon}`}/>
				{isApproved(userData) && !downloadsError && <GeneralButton className={styles.desktop} colorVariant='blackOnBlackOutline' onClick={() => setDownloadModalIsOpen(true)}>Downloads </GeneralButton>}
				<GeneralButton className={styles.desktop} to='/account' colorVariant='blackOnBlackOutline'>My Account</GeneralButton>
				<GeneralButton to='/cart' colorVariant='orangeFill'>Cart{count > 0 ? `(${count})`: ''}</GeneralButton>
			</div>
		</>
	)
	
	const signedOutJsx = (
		<>
			{leftJsx}
			<SearchBar/>
			<div className={styles.right}>
				<GeneralButton className={styles.signUp} to='/signUp' colorVariant='blackOnBlackOutline'><span className={styles.desktop}>Create account</span><span className={styles.mobile}>Sign In / Up</span></GeneralButton>
				<GeneralButton className={styles.signIn} to='/signIn' colorVariant='whiteNoOutline'>Sign In</GeneralButton>

			</div>
		</>
	);


	return (
		<>
			<nav className={styles.container}>
				<div className={styles.bar}>
					{isLoggedIn ? signedInJsx : signedOutJsx}
				</div>
			</nav>
			{downloadModalIsOpen && <DownloadModal isLoading={isLoadingDownloads} downloadItems={downloadsData?.data} triggerClose={() => setDownloadModalIsOpen(false)}/>}
		</>);


}

export default NavBar;