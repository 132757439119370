import { missingImageUrl, prependServerURL } from '../helpers/helper';
import CategoryThumbnail from './CategoryThumbnail';
import { useContext, useImperativeHandle, useState } from 'react';
import useFetch from '../helpers/useFetch';
import { Link, useParams } from 'react-router-dom';

import GeneralButton from './GeneralButton.js';

import styles from '../css/CategoryGallery.module.css';
import { AuthStateContext } from '../helpers/authContext';

const CategoryGallery = ({itemComponent, maxItems}) => {
	maxItems = maxItems ?? 10;

	const {userData} = useContext(AuthStateContext);
	const [category, setCategory] = useState(null);

	const qs = require('qs');
	const query = qs.stringify({
		sort: ['ranking:asc'],
		pagination: {
			start: 0,
			limit: maxItems
		},
		populate: {
			thumbnailImage: true
		}
	});

	const url = `${process.env.REACT_APP_STRAPI_URL}/api/categories?${query}`
	const [isLoading, categoryData, errors] = useFetch(url);

	const ItemComponent = itemComponent;
	let itemsJsx = [];
	if(categoryData) {
		itemsJsx = categoryData.data.map(category => {


			const thumbnailUrl = category.attributes.thumbnailImage.data === null ? missingImageUrl : prependServerURL(category.attributes.thumbnailImage.data.attributes.url, true, false);
			const categoriesParam = qs.stringify({category: category.attributes.name});
			return (
				<li key={category.attributes.name}>
					<Link to={`/products?${categoriesParam}`} style={{textDecoration: 'none'}}>
						<ItemComponent
							title={category.attributes.name}
							thumbnailUrl={thumbnailUrl}
							isLoadingPlaceholder={false}
						/>
					</Link>
				</li>

			)
		});

	}
	// categories should have a thumnail image



	return (
		<section className={styles.container}>
			<header className={styles.header}>
				<h2 className={styles.title}><span>Shop by</span> <span>category</span></h2>
				<GeneralButton className={styles.allCategoriesButton} to='/products' extraWidthlevel={1} slideInText='See all categories  →'>See all categories</GeneralButton>
			</header>
			<ul className={styles.list}>{itemsJsx}</ul>
		</section>
	);
		

	return <ItemComponent
		title='Golf'
		thumbnailUrl={missingImageUrl}
		imageSizeX='300'
		imageSizeY='300'
		isLoadingPlaceholder={isLoading}
	/>
	

}

export default CategoryGallery;