import Icon from './Icon';
import styles from '../css/DownloadModal.module.css';
import useFetch from '../helpers/useFetch';
import { prependServerURL } from '../helpers/helper';
import { formatStrapiDate } from '../helpers/helper';
import { useEffect, useState } from 'react';


const DownloadItem = ({fileName, fileExtention, url, size, updatedAt, onDownload, hasBeenDownloaded}) => {
	return <article className={styles.item}>
		<a href={prependServerURL(url)} className={styles.itemInner} onClick={() => onDownload(fileName)}>
			<header className={styles.itemHeader}>
				<span className={styles.itemTitleWrapper}> <h3 className={styles.itemTitle}>{fileName} </h3> <span>{fileExtention}</span></span>
				
				<span>{formatStrapiDate(updatedAt)}</span>
				<span>{size}kb</span>
			</header>

			<div className={styles.itemDownload}>
				{!hasBeenDownloaded && 
					<Icon className={styles.itemIcon} icon='download'/>
				}

				{hasBeenDownloaded && 
					<div className={styles.itemDownloadedWrapper}>
						<span>Downloaded!</span>
						<Icon className={styles.itemDownloadedIcon} icon='check'/>
					</div>
				}
			</div>
		</a>
	</article>
}

const DownloadModal = ({isLoading, downloadItems, triggerClose}) => {

	const handleClickBackground = (e) => {
		if(e.target === e.currentTarget) {
			triggerClose();
		}
	}

	const [downloadedFileNames, setDownloadedFileNames] = useState([]);
	const handleOnDownload = (fileName) => {
		if(!downloadedFileNames.includes(fileName)) {
			const copy = [...downloadedFileNames, fileName];
			setDownloadedFileNames(copy);
		}
	}

	return (
		<div className={styles.background} onClick={handleClickBackground}>
			<section className={styles.modal}>
				{isLoading && <Icon icon='loading' className={styles.loadingIcon}/>}
				{!isLoading && downloadItems && <>
					<Icon className={styles.closeIcon} icon='close' onClick={triggerClose}/>
					<header className={styles.header}>
						<Icon className={styles.headerIcon} icon='cloud'/>
						<h2 className={styles.title}>Files and downloads</h2>
					</header>
					{
						downloadItems.length > 0 &&
						<ul>
							{downloadItems.map(
								downloadItem => <li><DownloadItem fileName={downloadItem.FileName} hasBeenDownloaded={downloadedFileNames.includes(downloadItem.FileName)} fileExtention={downloadItem.File.ext} url={downloadItem.File.url} size={50} updatedAt={downloadItem.File.updatedAt} onDownload={handleOnDownload}/></li>)}
						</ul>
					}

					{
						downloadItems.length === 0 && <p className={styles.nothingToDownload}>Nothing to download.</p>
					}
				</>
				}
			</section>
		</div>
	)

	
}

export default DownloadModal;