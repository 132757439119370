import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/normalize.css';
import './index.css';
import App from './components/App';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthStateContextProvider from './helpers/authContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<Router>
			<AuthStateContextProvider>
				<App />
			</AuthStateContextProvider>
		</Router>
	</React.StrictMode>
);
