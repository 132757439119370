
import { useContext } from 'react';
import Page from './Page';
import styles from '../css/CartPage.module.css';
import { CartContext } from '../helpers/useCartContext';
import GeneralButton from './GeneralButton';
import { calculateFinalPrice, formatCurrency, getDisplayVariationData, isApproved, prependServerURL } from '../helpers/helper';
import CartItemRow from './CartItemRow';
import { ExclusiveTaxSpan } from './ExclusiveTaxSpan';
import { AuthStateContext } from '../helpers/authContext';

const NewCartPage = () => {
	const {userData} = useContext(AuthStateContext);
	const userIsApproved = isApproved(userData);
	// get all cart items. Already exist on client side.
	const {cartItems, changeCartItemsClient, updateAmountServer} = useContext(CartContext);
	
	let totalPrice = 0;
	const cartItemRowsJsx = cartItems.map((cartItem, index) => {
		if(cartItem?.isLoading !== undefined) {
			console.log('has is loading: ' + cartItem.isLoading)
		}

		const {basePrice, baseDiscount, amount, priceAfterPriceDrop, priceDropQuantity} = cartItem;
		const priceObject = calculateFinalPrice(basePrice, baseDiscount, amount, priceDropQuantity, priceAfterPriceDrop);
		const variationData = getDisplayVariationData(cartItem.variationData);
		totalPrice += cartItem.amount * priceObject.finalPrice;

		return (
			<li key={`${cartItem.id}`}>
				<CartItemRow
					title={cartItem.title}
					productId={cartItem.productId}
					imageUrl={prependServerURL(cartItem.imagePath)}
					variationData={variationData}
					basePrice={priceObject.basePrice}
					finalPrice={priceObject.finalPrice}
					totalDiscount={priceObject.discountPercentage}
					amount={amount}
					minimumAmount={cartItem.minimumAmount}
					updateAmountFunction={(newAmount) => {console.log(`new amount: ${newAmount}`); changeCartItemsClient(index, {amount: newAmount})}}
					triggerUpdateDatabase={(validatedValue) => {updateAmountServer(cartItem.id, cartItem.amount); console.log({validatedValue})}}
					deleteFunction={() => updateAmountServer(cartItem.id, 0)}
					isFrozen={cartItem?.isLoading}
					isDirty={cartItem.isDirty}
				/>
			</li>

		)
	})

	return (
		<Page  bHasNavBar={true} pageWidth='700px'>
			<section className={`${styles.card}`}>
				<header className={`${styles.header} ${styles.cardRow}`}>
					<h1 className={styles.title}>Cart</h1>
					<GeneralButton 
						to='/checkout'
						className={styles.howButton}
						disabled={cartItemRowsJsx.length === 0 || !userIsApproved} 
						>
						{!userIsApproved ? 'Accounts needs to be approved' : 'continue to checkout'}
					</GeneralButton>
					
				</header>

				{cartItemRowsJsx.length === 0 && 
					<div className={`${styles.emptyCart} ${styles.cardRow}`}>
						<p className={styles.noItems}>Your cart is empty</p>
						<GeneralButton to='/products' colorVariant='blackFill'>Browse products</GeneralButton>
					</div>
				}

				{cartItemRowsJsx.length > 0 &&
					<ul className={styles.cardRow} style={{listStyle:'none', padding:'0', margin: '0'}}>
						{cartItemRowsJsx}
					</ul>
				}

				<section className={`${styles.cardRow} ${styles.shipping}`}>
					<h2 className={styles.shippingTitle}>Shipping</h2>
					<p className={styles.shippingAmount}>Calculated later</p>
				</section>
				
				<section className={`${styles.cardRow} ${styles.total}`}>
					<h2 className={styles.totalTitle}>Total</h2>
					<p className={styles.totalAmount}>
						{formatCurrency(totalPrice)}
						
						<ExclusiveTaxSpan/>
						<span className={styles.underAmount}>
							+ shipping
						</span>
					</p>
				</section>
				<section className={styles.how}>
					<h2 className={styles.howTitle}>How it works</h2>
					<p className={styles.howParagraph}>
						No payments are made on the website.<br/>
						As soon as you finish checking out your cart <br/>
						you will receive an payment invoice via email from Belpo
					</p>
					<div className={styles.howButtonWrapper}>
							<GeneralButton 
								to='/checkout'
								className={styles.howButton}
								disabled={cartItemRowsJsx.length === 0 || !userIsApproved} 
								size='2'>
									{
										!userIsApproved ?
										'Not approved' : 'continue to checkout'

									}
							</GeneralButton>
							{!userIsApproved &&
								<p className={styles.unapprovedInfo}>Wait until Belpo has approved your account</p>
							}
					</div>
				</section>
			</section>

		</Page>
	);

}

export default NewCartPage;