import { Link } from 'react-router-dom';
import styles from '../css/ProductThumbnailMaster.module.css';
import { missingImageUrl,formatCurrency, formatSoldPer } from '../helpers/helper';
import { Bubble } from './Bubble';
import { BubblesContainer } from './BubblesContainer';
import { ExclusiveTaxSpan } from './ExclusiveTaxSpan';
import HiddenPrice from './HiddenPrice';
import { useContext } from 'react';
import { AuthStateContext } from '../helpers/authContext';

const ProductThumbnailMaster = ({title, priceObject, soldPer,nbColors, nbSizes, bShowSoldPer, to, thumbnailUrl, isNew, isFeatured}) => {
	const {userData} = useContext(AuthStateContext);
	thumbnailUrl ??= missingImageUrl;
	const priceIsHidden = priceObject === undefined;
	
	let priceJsx = null;
	if(!priceIsHidden) {
		priceJsx = (
			<>
				<div className={styles.priceContainer}>
				{priceObject.discountPercentage > 0 && <span className={styles.priceOld}>{formatCurrency(priceObject.basePrice)}</span>}
				<span className={`${styles.price} ${priceObject.discountPercentage > 0 ? styles.priceDiscounted : ''}`}>{formatCurrency(priceObject.finalPrice)}</span>
				<span className={styles.soldPer}> /{formatSoldPer(soldPer)}</span>
				</div>
				<ExclusiveTaxSpan/>
			</>
		);
	} else {
		priceJsx = <HiddenPrice isLoggedIn={userData} short/>
	}

	return (
		<Link to={to}>
		<article className={styles.container}>
			<div className={styles.imageWrapper} style={{position: 'relative'}}>
				<BubblesContainer style={{margin: 'var(--sp-2)'}}>
					{isNew && <Bubble>New!</Bubble>}
					{isFeatured && <Bubble>Featured</Bubble>}
					{priceObject?.discountPercentage && <Bubble>-{priceObject.discountPercentage}%</Bubble>}
				</BubblesContainer>
				<img className={styles.image} src={thumbnailUrl}/>
			</div>
			<div className={styles.gradient}></div>
			<header className={styles.header}>
				<div className={styles.variationInfoWrapper}>
					{nbColors && <p>{nbColors} Color{nbColors > 1 ? 's' : ''}</p>}
					{nbSizes && <p>{nbSizes} Size{nbSizes > 1 ? 's' : ''}</p>}
				</div>
				<h3 className={styles.title}>{title}</h3>
				{ priceJsx }
			</header>
		</article>
		</Link>

	)
}

export default ProductThumbnailMaster;