import Page from './Page.js';
import CheckoutCart from './CheckoutCart.js';
import CheckoutForm from './CheckoutForm.js';
import styles from '../css/CheckoutPage.module.css';

const CheckoutPage = () => {

	return (
		<Page mainClassName={styles.pageContent}>
			<div className={styles.twoCardWrapper}>
				<CheckoutCart className={styles.cart}/>
				<CheckoutForm className={styles.form}/>
			</div>
		</Page>
	);
}

export default CheckoutPage;