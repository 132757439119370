import { useContext, useMemo, useState } from 'react';
import InputTextUpdateField from './InputTextUpdateField';
import styles from '../css/AccountPage.module.css';
import { InputSelectUpdateField, InputSelectUpdateFields } from './InputSelectUpdateField';
import { AuthStateContext } from '../helpers/authContext';

const AccountCompanyInfo = ({ countryOptions}) => {

	// get all info (but it already is in logindata)
	const { userData, loginWithJwt} = useContext(AuthStateContext);

	const defaultStateMemo = useMemo(() => [
			{
				type: 'country',
				label: 'Country',
				serverValue: userData?.companyCountry?.id,
				clientValue: userData?.companyCountry?.id,
				name: 'companyCountry',
				state: 'inactive',
				error: '',
				isRequired: true,
				saved: false
			},
			{
				type: 'text',
				label: 'City',
				serverValue: userData?.companyCity,
				clientValue: userData?.companyCity,
				name: 'companyCity',
				state: 'inactive',
				error: '',
				isRequired: true,
				saved: false
			},
			{
				type: 'text',
				label: 'PostalCode',
				serverValue: userData?.companyPostalCode,
				clientValue: userData?.companyPostalCode,
				name: 'companyPostalCode',
				state: 'inactive',
				error: '',
				isRequired: true,
				saved: false
			},
			{
				type: 'text',
				label: 'Steet name & number',
				serverValue: userData?.companyStreetNameAndNumber,
				clientValue: userData?.companyStreetNameAndNumber,
				name: 'companyStreetNameAndNumber',
				state: 'inactive',
				error: '',
				isRequired: true,
				saved: false
			},
			{
				type: 'text',
				label: 'Appartement, suite, etc.',
				serverValue: userData?.companyAddressAddition,
				clientValue: userData?.companyAddressAddition,
				name: 'companyAddressAddition',
				state: 'inactive',
				error: '',
				isRequired: true,
				saved: false
			},
			{
				type: 'text',
				label: 'Phone',
				serverValue: userData?.phone,
				clientValue: userData?.phone,
				name: 'phone',
				state: 'inactive',
				error: '',
				isRequired: true,
				pattern: /^\+*\d*$/,
				saved: false
			},
			{
				type: 'text',
				label: 'Company Website',
				serverValue: userData?.companyWebsite,
				clientValue: userData?.companyWebsite,
				name: 'companyWebsite',
				state: 'inactive',
				error: '',
				isRequired: false,
				saved: false
			},
			{
				type: 'text',
				label: 'VAT',
				serverValue: userData?.vat,
				clientValue: userData?.vat,
				name: 'vat',
				state: 'inactive',
				error: '',
				isRequired: true,
				saved: false
			},
			
		], [userData]);

	const [updateFieldsState, setUpdateFieldsState] = useState(defaultStateMemo);

	const changeFieldState = (id, changesObj) => {
		const newFieldState  = {...updateFieldsState[id]};
		Object.entries(changesObj).forEach((entry) => {
			const [key, value] = entry;
			newFieldState[key] = value;
		});

		// is this allowed?
		const newArray = [...updateFieldsState];
		newArray[id] = newFieldState;
		setUpdateFieldsState(newArray);
	}

	const handleOnFocus = (id) => {
		// if has error -> on focus go back to server value 
		if(updateFieldsState[id].error) {
			changeFieldState(id, {clientValue: updateFieldsState[id].serverValue, error: ''});
		}
	}

	const fieldsJsx = () => {
		return updateFieldsState.map((fieldData, index) => {


			if(fieldData.type === 'text'){

				return <li key={fieldData.name}>
				<InputTextUpdateField 
							label={fieldData.label}
							name={fieldData.name}
							isRequired={fieldData.isRequired}
							clientValue={fieldData.clientValue}
							error={fieldData.error}
							state={fieldData.state}
							pattern={fieldData.pattern}
							saved={fieldData.saved}
							triggerOnChange={(e) => changeFieldState(index, {clientValue: e.target.value, state: 'editing'})}
							triggerOnFocus={() => handleOnFocus(index)}
							triggerOnSubmit={() => handleOnSubmit(index)}
							triggerOnBlur={() => handleOnBlur(index)}
							/>
				</li>
				} else if(fieldData.type === 'country') {
					return <li key={fieldData.name}>
						<InputSelectUpdateField
							options={countryOptions}
							label={fieldData.label}
							name={fieldData.name}
							isRequired={fieldData.isRequired}
							clientValue={fieldData.clientValue}
							error={fieldData.error}
							state={fieldData.state}
							saved={fieldData.saved}
							triggerOnChange={(e) => {changeFieldState(index, {clientValue: e.target.value, state:'editing'}); handleOnFocus(index); updateFieldsState[index].clientValue = e.target.value; handleOnSubmit(index)}}
							triggerOnFocus={() => handleOnFocus(index)}
							triggerOnBlur={() => handleOnBlur(index)}
						/>
					</li>;

				}
		} )
	}

	const handleOnSubmit = async (id) => {
		// state to updating
		changeFieldState(id, {state: 'updating'});

		
		// trigger async function
		const asyncUpdateDb = async (id, name, value) => {
			// fetch the post update
			const body = {};
			body[name] = value;

			const url = `${process.env.REACT_APP_STRAPI_URL}/api/users-permissions/user_customUpdate` // Create server function to handle this
			const response = await fetch(url, {
				method: 'PUT',
				credentials: 'include',
				headers: {
					"Content-Type" : "application/json",
				},
				body: JSON.stringify(body),
			});

			if(!response.ok) {
				// handle general error
				return;
			}


			// wait until back -> call change fieldState but with all variables?
			const result = await response.json();			
			for(const errorKey in result.errors) {
				// response.errors.length is always max 1 in this situation.
				changeFieldState(id, {state: 'inactive', error: result.errors[errorKey]});
				
			}
			
			for(const updateKey in result.updates) {

				const serverValue = result.updates[updateKey];
				const clientValue = result.updates[updateKey];
				changeFieldState(id, {state: 'updated', serverValue: result.updates[updateKey], clientValue: result.updates[updateKey]});
			}

			if(Object.keys(result.updates).length > 0) {
				loginWithJwt();
			}
		}

		asyncUpdateDb(id, updateFieldsState[id].name, updateFieldsState[id].clientValue);
	}

	const handleOnBlur = (id) => {
		return;
	}


	return (
	<div>
		<h2 className={styles.panelTitle}>Company Details</h2>
		<ul >
			{fieldsJsx()}
		</ul>



	</div>
	);

}

export default AccountCompanyInfo;