import { useContext } from 'react';
import { AuthStateContext } from '../helpers/authContext';
import { isApproved } from '../helpers/helper';
import { useNavigate } from 'react-router-dom';

const RequireApproved = ({children}) => {
	const navigate = useNavigate();
	const {userData} = useContext(AuthStateContext);
	if(!isApproved(userData)) {
		navigate("/");
		return <div>Requires approval...</div>
	}
	return children
}

export default RequireApproved;