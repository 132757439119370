import styles from '../css/CategoryFilter.module.css'


//Categories have signature
// [{
//	attributes: {name},
//	id
//	}]
//	id = categories[0].id;
//	name = categories[0].attributes.name


const CategoryBubble = ({children, isSelected, onCategoryClick}) => {
	return <div onClick={onCategoryClick} className={`${styles.bubble} ${isSelected ? styles.bubbleActive : styles.bubbleInactive}`}>{children}</div>
}

// Selected Category Ids -> Ids refer to the database id
const CategoryFilter = ({categories, selectedCategories, toggleCategoryByName}) => {
	categories ??= [];

	const categoriesJsx = categories.map(category => {
		const isSelected = selectedCategories.indexOf(category.attributes.name) > -1;
		return (
		<li key={`bubble-${category.id}`}>
			<CategoryBubble isSelected={isSelected} onCategoryClick={() => toggleCategoryByName(category.attributes.name)}>
					{category.attributes.name}
			</CategoryBubble>
		</li>);
	});

	return (
		<ul className={styles.categories} style={{listStyle: 'none', padding: '0'}}>
			{categoriesJsx}
		</ul>
	);

}

export default CategoryFilter;