import { useContext } from 'react';
import { Navigate,useLocation, useNavigate } from 'react-router-dom'
import { AuthStateContext } from '../helpers/authContext';


const RequireLoggedOut = ({children}) => {

	const {userData, loadAuthStateFromLocalStorage} = useContext(AuthStateContext);
	const navigate = useNavigate();
	const location = useLocation();

	if(userData) {
		console.log('RequireLoggedOut. Expected pathname to not start with /. If so change navigate call!!');
		console.log({pathname: location.pathname})
		navigate(`/`);
		return <div>Redirecting to store home page</div>;
	} else if(!loadAuthStateFromLocalStorage().ok) {
		return children;
	}
} 

export default RequireLoggedOut;