import {Link} from 'react-router-dom';
import style from '../css/GeneralButton.module.css';

//colorVariant
// 'blackFill'
// 'blackOutline'
// 'orangeFill'

const GeneralButton = ({children, className, to, slideInText, extraWidthlevel, colorVariant, onClick, size, padding, disabled, isLoading}) => {
	slideInText ??= children;
	extraWidthlevel ??= 0;
	colorVariant ??= 'blackFill';
	size ??= 0;
	isLoading ??= false;

	disabled = isLoading ? true : disabled;

	const levelToPx = {
		0: '0px',
		1: '10px',
		2: '30px',
		3: '50px'
	}

	const sizeClass ={
		0: 'size-0',
		1: 'size-1',
		2: 'size-2',
	}

	const getSizeClass = () => {
		if(`${size}` in sizeClass) {
			return sizeClass[size];
		}
		return sizeClass[0];
	}

	const getExtraWidth = (level) => {
		if(!(extraWidthlevel in levelToPx)) 
		{
			return '0px';
		}
		
		return levelToPx[extraWidthlevel];
	}



	if (to && !disabled) {
	return (
	<Link 
		to={to} 
		className={`${style.button} ${style[colorVariant]} ${className} ${style[getSizeClass()]} ${disabled ? style.disabled : ''} `} 
		style={{textDecoration: 'none'}}
		onClick={(e) => disabled ? onClick(e) : () => {}}
		
	>
		<span className={`${style.text}`} style={{padding: `0 ${getExtraWidth(extraWidthlevel)}`}}>{children}<span className={style.slideIn}>{slideInText}</span></span>
	</Link>);
	} else {
		return (
		<div 
			className={`${style.button} ${style[colorVariant]} ${className} ${style[getSizeClass()]} ${disabled ? style.disabled : ''} `} 
			style={{textDecoration: 'none'}}
			onClick={(e) => !disabled ? onClick(e) : () => {}}
			
		>
			<span className={`${style.text}`} style={{padding: `0 ${getExtraWidth(extraWidthlevel)}`}}>{children}<span className={style.slideIn}>{slideInText}</span></span>
	</div>);

	}
}

export default GeneralButton;