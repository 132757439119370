import { Link, useParams } from 'react-router-dom'
import useFetch from '../helpers/useFetch';
import Page from './Page';
import ProductGallery from './ProductGallery';
import styles from '../css/NewDetailPage.module.css';
import GeneralButton from './GeneralButton';
import InputSelectControlled from './InputSelectControlled';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { calculateFinalPrice, formatCurrency, formatSoldPer, isApproved, missingImageUrl, prependServerURL, replaceNewLines } from '../helpers/helper';
import { CartContext } from '../helpers/useCartContext';
import GeneralNumberFieldUncontrolled from './GeneralNumberFieldUncontrolled';
import { ExclusiveTaxSpan } from './ExclusiveTaxSpan';
import { marked } from 'marked';
import usePost from '../helpers/usePost';
import HiddenPrice from './HiddenPrice';
import { AuthStateContext } from '../helpers/authContext';


// toggle
const enablePreviewPricesInDropDown = false;


const NewDetailPage = ({loginData, addViewHistoryId}) => {
	const {userData, jwt} = useContext(AuthStateContext);
	// which product am I?
	const {id} = useParams();
	useEffect(() => {
		if(id) {
			console.log(`product ${id} has been added to history.`);
			addViewHistoryId(id);
		}

	}, [id]);

	// You are a scottish blanket.
	const {refetchCartItems} = useContext(CartContext);
	/* ___________________________________________________ States & hooks Initialization */

	const url = `${process.env.REACT_APP_STRAPI_URL}/api/products/customFind`;
	const bodyMemo = useMemo(() => {
		return {
			productIds: [id],
			populateRelatedProducts: true,
			populateImagesLinkedToThisVariation: true,
			limit: 1
		}
	}, [id])

	

	const [isLoading, data, errors] = usePost(url, bodyMemo);

console.log({data});

	const [selectedImageName, setSelectedImageName] = useState('');
	const [feedback, setFeedback] = useState({successes: {}, errors: {} });
	const [amount, setAmount] = useState(1);
	const [cartFeedbackAnimation, setCartFeedbackAnimation] = useState(1);



	/* ___________________________________________________ Handling server data */
	const baseProduct = useMemo(() => {
		if(data?.length > 0) {

			const {created_at, isFeatured, name,description, discountPercent, categories} = data[0];
			if(categories.length === 0 ) {
				// create a fake category (just because it looks better than having nothing above the title of product)
				categories.push({name: 'all products', link: '/products'});
			}
			return {
				name,
				description,
				created_at,
				isFeatured,
				discountPercent,
				categories
			};
		}
		return [];

	}, [data]);


	let [variationsMemo, allImagesMemo] = useMemo(() => {

		if(data?.length > 0) {
			let masterVariationDetected = false;
			let variations = [];
			let allImages = [];
			data[0].variations.forEach((variation, id) => {
				if(masterVariationDetected) 
					return;

				const {basePrice, baseDiscount, inStock, size, colorName, priceAfterPriceDrop, priceDropQuantity, overrideOtherVariants, minimumAmount,variationDescription} = variation;
				if(overrideOtherVariants) {
					masterVariationDetected = true;
				}
				const newVariation = {colorDisplay: colorName, size, basePrice, baseDiscount, inStock, priceAfterPriceDrop, priceDropQuantity, imageNames: [], minimumAmount, variationDescription}
				
				variation.imagesLinkedToThisVariation?.forEach(image => {
					newVariation.imageNames.push(image.hash);
					allImages.push({
						hash: image.hash, // or use image.name here?
						url: prependServerURL(image.url), 
						size: size,
						colorName: colorName,
					});
				}); 

				variations.push(newVariation);
			});


			/* Edge case: 
			There is at least one variant with a colorName  
			&& there is at least one variant with no colorName
			=> Filter out the ones without a colorName. This is an error by the one who added the product.
			*/

			/* Edge case: no images provided  */
			if(allImages.length === 0 ) {
				allImages.push({
					hash: '',
					url: missingImageUrl,
					size: '',
					colorName: ''
				});
			}
			return[variations, allImages];
		} else {
			return [[],[]]; // empty
		}


	
	}, [data]);

	const colorsOptionsMemo = useMemo(() => {
		if(data) {
			const variations = variationsMemo;
			let uniqueColors = []; 
			variations.forEach(variation => {
				if(!uniqueColors.find(uniqueColor => uniqueColor.value?.toLowerCase() === variation?.colorDisplay?.toLowerCase())) {
					if(variation.colorDisplay) {
						uniqueColors.push({value: variation.colorDisplay, display:variation.colorDisplay, disabled: false});
					}
				}
			})
			return uniqueColors;
			//return uniqueColors.map(color => ({value: color.toLowerCase().match(/[a-zA-Z\d]/g).join(''), display: color}))
		} else return [];
	}, [variationsMemo]);



	/* ______________________________________________________________ Dynamic logic based on selections */
	const previousVariation = useRef(null); // state doesn't feel right to use here. 
	const selectedImageHash = useRef(null);
	const [selection, setSelection] = useState(null);

	useEffect(() => {
		setSelection(null);
	}, [id])


	

	const getFallbackVariation = () => {
		// select first valid variation
		// what makes a variation invalid? Not in stock (if other are in stock), 
		let inStockVariationIds = [];
		if(variationsMemo?.length > 0){

			variationsMemo.forEach((variation, id) => {if (variation.inStock) inStockVariationIds.push(id)});
			if (inStockVariationIds.length === 0) {
				return variationsMemo[0];
			} else {
				return variationsMemo[inStockVariationIds[0]]; 
			}
		} else {
			return {
				basePrice: 0,
				baseDiscount: 0,
				priceDropQuantity: 0,
				priceAfterPriceDrop: 0,
				colorDisplay: '',
				minimumAmount: 1,
				soldInpacksOf: 1,
			};
		
		}
	}


	// 2 cases: either am image is selected
	// or selection is done through 
	const selectImage = () => {
		// try find current in strict valid images (size & color match selection)
		// try find current in lax valid images (size match selection)
		// if found -> keep selected
		// if not found -> select first of valid images
		if(!allImagesMemo?.length)	return;
	
		let validImages = allImagesMemo.filter(imageObj => imageObj.colorName === myVariation.colorDisplay && imageObj.size === myVariation.size);
		if(validImages?.length < 1)
		{
			validImages = allImagesMemo.filter(imageObj => imageObj.colorName === myVariation.colorName);
		}

		
		// reset the selected image hash 		
		selectedImageHash.current = null;
		if(selection?.hasOwnProperty('imageHash')) {
			selectedImageHash.current = selection.imageHash;
		}

		if(selectedImageHash.current === null && validImages?.length > 0) {
			// select first 
			selectedImageHash.current = validImages[0].hash;
		}
	}

	const addPriceObjectToVariation = (variation) => {
		const {basePrice, baseDiscount, priceAfterPriceDrop, priceDropQuantity} = variation;
		const priceObj = calculateFinalPrice( basePrice, baseDiscount, amount, priceDropQuantity, priceAfterPriceDrop);
		variation.priceObject = priceObj;
	}

	const getMostRelevantVariation = (color, size, imageHash) => {
		
		// Try match color and size
		let relevantVariations = variationsMemo.filter((variation) => {
			let colorMatches = true;
			if(color !== undefined) {
				colorMatches = variation.colorDisplay === color;
			}

			let sizeMatches = true;
			if(size !== undefined && false) {
				// We can't do this.
				// If color changes but the same size by name is available we want to select that size. if not default to first relevant size of that color
				// Size can be invalid for a specified color.
				// We can't assume size is a valid size for this color.
				// color is more important than size.
				sizeMatches = variation.size === size;
			}

			let imageMatches = true;
			if(imageHash !== undefined) {

				// Note: Red M and Red L use the same 2 images
				// if I have Red L selected and select the second image, I expect my size selection not to change.
				// -> If i have an image selected, I need to check if the selected image is ok for the previous size&color selection. If so, choose the that variation, if not, choose the first variation that is ok. 
				imageMatches = variation.imageNames.includes(imageHash);
			}

			return colorMatches && sizeMatches && imageMatches;
		});

		// extra check to prioritze not changing the selected variation over 
		let mostRelevantVariation = undefined;
		if(relevantVariations.length > 0 ) {
			mostRelevantVariation = relevantVariations[0];

			if(size !== undefined) {
				// check if size is available in this range of variations for this color
				const matchingSizeAndColorVariations = relevantVariations.filter(variation => variation.size === size);
				mostRelevantVariation = matchingSizeAndColorVariations.length > 0 ? matchingSizeAndColorVariations[0] : relevantVariations[0];
			}

			if(imageHash !== undefined) {
				const previousVariationStillValid = previousVariation.current?.imageHash === imageHash;
				if(previousVariationStillValid) {
					mostRelevantVariation = previousVariation.current;
				}
			}
		}
		return relevantVariations.length > 0 ? mostRelevantVariation : getFallbackVariation();
	}

	const myVariation = getMostRelevantVariation(selection?.color, selection?.size, selection?.imageHash);	

	addPriceObjectToVariation(myVariation);
	selectImage();

	const handleSizeChange = (e) => {
		// keep same color
		// set size
		// setSelection({color: oldColor, size: newSize});

		const staleColor = selection?.color;
		setSelection({size: e.target.value, color: staleColor})
	}

	const handleColorChange = (e) => {
		// size need to be reset to the first matching
		const newSelectedColor = e.target.value;
		const oldSize = selection?.size;
		// no need to check anything because invalid selections are caught while selecting the variation.
		setSelection({size: oldSize, color: newSelectedColor});
	}

	const getPriceForVariation = (variation) => {
		// base price with discount?
		return calculateFinalPrice(variation.basePrice, variation.baseDiscount, 1, variation.priceDropQuantity, variation.priceAfterPriceDrop).finalPrice;
	}

	let sizeOptions2 = variationsMemo.map(variation => {
		if(myVariation && variation.colorDisplay === myVariation.colorDisplay && variation.size){
			const option = {value: variation.size, display: `${variation.size}`, disabled: !variation.inStock};
			if(enablePreviewPricesInDropDown) {
				option.extraDisplay = ` → ${formatCurrency(getPriceForVariation(variation))}`;
			}
			return option;
		}
		return undefined;
	}).filter(element => element !== undefined);





	/* ______________________________________________________________ Images preparation for Jsx */
	// array of al unique images (don't show duplicate images)
	// function to select an image -> will not change selected color & size
	let gallery = [];
	if(data) {
		allImagesMemo.forEach(imageObj => {
			const alreadyInGallery = gallery.findIndex(element => element.hash === imageObj.hash);
			if(alreadyInGallery === -1) {
				gallery.push(imageObj); // is this a deep copy or shallow?
			}
		});
	}

	
	
	const handleAddToCart = () => {

		const asyncAddToCart = async (productId, amount, colorName, size) => {
			// what if product does not have color variant?
			const url = `${process.env.REACT_APP_STRAPI_URL}/api/cart_items/user_addCartItem`
			const addResponse = await fetch(url, {
				method: 'POST',
				credentials: 'include',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${jwt}`
				},
				body: JSON.stringify({ 
					productId,
					amount, 
					colorName,
					size
				})
			});
			
			if(addResponse.ok) {
				const result = await addResponse.json();
				if(result?.action === 'cart item added') {
					setFeedback({successes: {general:'Added to cart!'}})
					refetchCartItems();
					const old = cartFeedbackAnimation;
					setCartFeedbackAnimation(0)
					setTimeout(() => {
						setCartFeedbackAnimation(old => old+1)
					}, 10);
					// button should update to be green and add text 
					// update shopping cart
				} else if(result?.action === 'show feedback') {
					setFeedback({successes: {}, errors: result.errors});
				}
			}
		} 

		asyncAddToCart(id, amount, myVariation.colorDisplay, myVariation.size);
	}

	const priceIsHidden = myVariation?.basePrice ? false: true;
	let priceJsx = null;
	if(!priceIsHidden) {
		priceJsx = (
			<div className={styles.priceWrapper}>
				{myVariation.priceObject.discountApplied  &&
					<div className={styles.discountWrapper}>
						<span className={styles.strikedPrice}>{formatCurrency(myVariation.priceObject.basePrice)}</span>
						<span className={styles.discount}>&#x200B;{`-${myVariation.priceObject.discountPercentage}%`}</span>
					</div>
				}
				<span className={styles.currentPrice}>{formatCurrency(myVariation.priceObject.finalPrice)}<span className={styles.soldPer}> / {formatSoldPer(myVariation.soldInpacksOf)}</span> <ExclusiveTaxSpan style={{fontWeight: 'normal', marginLeft: 'var(--sp-0'}} parentheses/></span>
				{myVariation.priceDropQuantity > 0 && <span className={`${styles.bulkDiscount} ${amount >= myVariation.priceDropQuantity ? styles.bulkDiscountApplied : ''}`}><span onClick={() => amount < myVariation.priceDropQuantity ? setAmount(myVariation.priceDropQuantity ) : ''}><span className={styles.bulkDiscountPrice}>{formatCurrency(myVariation.priceAfterPriceDrop * (1 - myVariation.baseDiscount/100))}</span>{` if you buy ${myVariation.priceDropQuantity} or more `}<u><strong>apply</strong></u></span></span>}
				{myVariation.priceDropQuantity <= 0 && <span className={styles.bulkDiscount}></span>}
			</div>
			);
	} else {
		priceJsx = <HiddenPrice className={styles.priceReplacement} isLoggedIn={userData} short={false}/>
	}
 

	return (
		<Page bHasNavBar={true} className={styles.page}>
			{isLoading && <div></div>}
			<article className={styles.card}>
				<div className={styles.left}>
					<div className={styles.galleryMain}>
						{gallery?.length > 0 && <img className={styles.galleryMainImage} src={gallery.find(element => element.hash === selectedImageHash.current)?.url}/>}
					</div>
					<div className={styles.galleryBellow}>
						{
							gallery.map((image) => {
								return <div onClick={() => setSelection({imageHash: image.hash})} className={`${styles.gallerySmallWrapper} ${selectedImageHash.current === image.hash ? styles.gallerySmallWrapperActive : ''}`}><img className={styles.gallerySmall} src={image.url}/></div>
							})
						}
					</div>
				</div>
				<form className={styles.right}>
					<header>
						<ul className={styles.categories}>
							{baseProduct?.categories && baseProduct.categories.map(category => {
								return <li key={category.name} className={styles.category}><Link to={category?.link ? category.link : `/products?category=${category.name}`}>{category.name}</Link></li>
							})}
						</ul>
						<h2 className={styles.title}>{baseProduct.name}</h2>

					</header>
					
					<p className={styles.marked} dangerouslySetInnerHTML={{__html: baseProduct?.description ? marked.parse(replaceNewLines(baseProduct.description), {breaks: true, mangle: false, headerIds: false}): ''}}></p>
				{
					priceJsx
				}
					
					<div className={styles.variations}>
						{colorsOptionsMemo.length > 0 && <InputSelectControlled label='Color' options={colorsOptionsMemo} name='color' selectedOption={myVariation?.colorDisplay} onChange={handleColorChange}/>}
						{sizeOptions2.length > 0 && <InputSelectControlled label='Size' options={sizeOptions2} name='size' selectedOption={myVariation?.size} onChange={handleSizeChange}/>}
					</div>

					<div className={styles.amountAndSubmitWrapper}>
						<div className={styles.amountAndSubmit}>
							<GeneralNumberFieldUncontrolled name='amount' value={amount} defaultValue={myVariation.minimumAmount} min={myVariation.minimumAmount} onChange={(newAmount) => {setAmount(old => newAmount)}}/>
							{/* {feedback?.successes?.general && <GeneralButton disabled={!isApproved(userData) || (variationsMemo && !myVariation?.inStock)} colorVariant='blackFill' onClick={handleAddToCart} to='/cart'>Go to cart 🛒</GeneralButton>} */}
							<GeneralButton disabled={!isApproved(userData) || (variationsMemo && !myVariation?.inStock)} colorVariant='orangeFill' onClick={handleAddToCart}>Add to cart</GeneralButton>
						</div>
							{feedback?.successes?.general && cartFeedbackAnimation > 0 && <a href="/cart" className={styles.addedToCartFeedback}>Product has been added to your cart ✅</a>}
						<span className={styles.minimumAmount}>{myVariation?.minimumAmount > 1 && `Minimum quatinty: ${myVariation.minimumAmount}`}</span>
					</div>
							{ myVariation?.variationDescription &&
								

					<div className={styles.variationDescriptionWrapper}>
						<p className={styles.variationDescriptionTitle}>Extra Information</p>
						
						<div className={styles.marked} dangerouslySetInnerHTML={{__html: marked.parse(replaceNewLines(myVariation.variationDescription), {mangle: false, headerIds: false})}}></div>
					</div>
					}

				</form>



			</article>
			<ProductGallery small className={styles.relatedProducts} title='Related products' products={data?.length ? data[0].relatedProducts : []} loginData={loginData}/>
			<ProductGallery small className={styles.relatedProducts} title='Recently viewed' queryType='recently visited' loginData={loginData} excludedProductIds={[id]}/>
		</Page>
	);
}


export default NewDetailPage;