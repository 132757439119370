import { useState } from 'react';
import styles from '../css/inputs.module.css';

// 'componentUnderField' is used for the 'forgot password?' link on the signinpage
const InputText = ({children, type, name, error, onFocus, signalOnChange, regex, disabled, defaultValue, isRequired, componentUnderField}) => {
	type ??= 'text'
	isRequired ??= false;
	onFocus ??= () => {};
	defaultValue ??= ''; 
	signalOnChange ??= () => {};
	const [content, setContent] = useState(defaultValue);

	const updateContent = (newContent) => {
		if(regex) {
			if(regex.test(newContent)){
				setContent(newContent);
			}
			return;
		}

		console.log(newContent);
		setContent(newContent);
	}

	return (
	<div className={`${styles.wrapper} ${error ? styles.hasError : ''}`}>
		<label className={styles.label} htmlFor={name}>{children}{isRequired ? '*' : ''}</label>
		<input disabled={disabled} className={`${styles.input} ${error ? styles.inputError : ''}`} name={name} type={type} id={name} value={content} onChange={(e) => {signalOnChange(e); updateContent(e.target.value)}} onFocus={onFocus}></input>
		{componentUnderField !== null && componentUnderField}
		<span className={`${styles.message} ${styles.errorMessage}`}>{error}</span>
	</div>)

}

export default InputText;