import { createContext, useRef, useState } from 'react';
import { storeJWT, storeUserData, readUserData, readJWT } from './helper';
import { useNavigate } from 'react-router-dom';

export const AuthStateContext = createContext(null);

const AuthStateContextProvider = ({ children }) => {
	//const [authState, setAuthState] = useState({ user: null, jwt: null });
	const userDataRef = useRef(null);
	const jwtRef = useRef(null);
	const navigate = useNavigate();

	const login = async (email, password) => {
		const url = `${process.env.REACT_APP_STRAPI_URL}/api/users-permissions/login`; // This route is same as /auth/local but with middleware that adds jwt httponly cookie.
		const options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				identifier: email,
				password: password,
			}),
		};

		const response = await fetch(url, options);
		const result = await response.json();
		if (response.ok) {

			console.log('[Login] Check shape of result. ')
			console.log(result);
			storeJWT(result.jwt);
			storeUserData(JSON.stringify(result.user));
			userDataRef.current = result.user;
			jwtRef.current = result.jwt;
			//setAuthState({ user: result.user, jwt: result.jwt });
			navigate('/');
			return { ok: true };
		} else {
			storeJWT('');
			storeUserData('');
			userDataRef.current = null;
			jwtRef.current = null;
			//setAuthState(newAuthState);
			const errors =
				result.error?.details?.length > 0
					? result.error.details.errors
					: [{ name: result.error.name, message: result.error.message }];
			console.log('failed to login and handling errorright now.');
			console.log(result);
			return { ok: false, errors };
		}
	};

	const logout = () => {
		storeJWT('');
		storeUserData('');
		userDataRef.current = null;
		jwtRef.current = null;
		//setAuthState(newAuthState);
		navigate('/'); // should be enough because should auto go to signin
	};

	const loginWithJwt = async (jwt, from) => {
		const url = `${process.env.REACT_APP_STRAPI_URL}/api/users-permissions/loginWithJwt`;
		const response = await fetch(url, {
			method: 'POST',
			Authorization: `Bearer ${jwt}`,
		});
		if (!response.ok) {
			console.log('LoginWithJWT failed. Invalid jwt');
			storeJWT('');
			storeUserData('');

			userDataRef.current = null;
			jwtRef.current = null;
			//setAuthState({ user: null, jwt: null });
		}
		console.log('logged in with jwt');
		const body = await response.json();
		console.log(
			'login with jwt -> EXPECTED: {user, jwt}. If not -> change LoginWithJwtIfStored()!!'
		);
		console.log(body);
		storeUserData(body.user); // CONFIRM IF THIS IS RIGHT SHAPE
			userDataRef.current = body.user;
			jwtRef.current = body.jwt

		navigate(from ?? '/')
		//setAuthState(newAuthState);
	};

	const loginWithJwtIfStored = async (from='/') => {
		const jwt = readJWT();
		if (jwt) {
			loginWithJwt(jwt, from);
			return { ok: true };
		}
		storeUserData('');
		userDataRef.current = null;
		jwtRef.current = null;
		return { ok: false };
	};

	const loadAuthStateFromLocalStorage = (from="/") => {
		const jwt = readJWT();
		const userData = readUserData();

		console.log({readResult: readUserData()});
		if (!jwt) {
			return { ok: false };
		}
		if (!userData || (typeof userData === 'string' && userData?.length === 0)) {

			return loginWithJwtIfStored(from);
		} else {

			userDataRef.current = JSON.parse(userData);
			jwtRef.current = jwt;
			console.log(userData)
			console.log(jwt)
	
			console.log(`loading auth local storage. naviage to ${from ?? '/'}`)
			return { ok: true };
		}
	};


	if(!userDataRef.current || !jwtRef.current) {
		loadAuthStateFromLocalStorage()
	}

	return (
		<AuthStateContext.Provider
			value={{
				userData: userDataRef.current,
				jwt: jwtRef.current,
				login,
				logout,
				loadAuthStateFromLocalStorage,
				loginWithJwt: (from) =>  loginWithJwt(jwtRef.current, from)
			}}
		>
			{children}
		</AuthStateContext.Provider>
	);
};

export default AuthStateContextProvider;
