import styles from '../css/Page.module.css';
import NavBar from './NavBar';

const Page = ({children, bHasNavBar, logout, className, mainClassName, contentClassName, pageWidth}) => {
	pageWidth ??= '100%';

	return <div className={`${styles.page} ${className}`}>
		{bHasNavBar && <NavBar />}
		{bHasNavBar && <div className={styles.navBarArea}></div>}
		<main className={`${styles.pageContent} ${mainClassName}`}>
			<div className={contentClassName} style={{maxWidth:pageWidth, width: '100%', height: '100%'}}>{children}</div>
		</main>
	</div>
}

export default Page;