import CategoryGallery from './CategoryGallery';
import ProductGallery from './ProductGallery';
import CategoryThumbnail from './CategoryThumbnail';
import Page from './Page.js';
import InfoCard from './InfoCard'
import { isApproved } from '../helpers/helper';
import { AuthStateContext } from '../helpers/authContext';
import { useContext } from 'react';

const HomePage = ({fetchedComponentsJsx}) => {

	const {userData} = useContext(AuthStateContext);
	// if not logged in -> display: Your view is restricted. To view prices and more, create an account and get approved by Belpo.
	// if Logged in but not approved yet -> didsplay 
	let infoContentJsx = null;

	if(!userData) {
		infoContentJsx = <span>Your view is restricted. To view prices and more, <strong>create an account</strong> and get approved by Belpo</span>;
	} else if (!isApproved(userData)) {
		infoContentJsx = <span>Your view is restricted. To view prices and more, you need to be approved by Belpo.</span>;
	}

	return (<Page bHasNavBar={true} pageWidth='1200px'>
		{infoContentJsx && <InfoCard >{infoContentJsx}</InfoCard>}
	<CategoryGallery itemComponent={CategoryThumbnail} userData={userData}/>
	{ fetchedComponentsJsx }
	</Page>);

};

export default HomePage; 
