import { useState } from 'react';
import styles from '../css/inputs.module.css';

const InputSelectControlled = ({label, required, options, name, noneOption, selectedOption, onChange, disabledSuffix, error, onFocus}) => {
	options ??= [];
	required ??= false;
	name ??= '';
	label ??= '';
	disabledSuffix ??= '';
	onFocus ??= () => {}
	onChange ??= () => {}
	const [hasChanged, setHasChanged] = useState(false);
	error ??= undefined;

	const optionsCopy = [...options]
	if(noneOption) {
		optionsCopy.unshift({value:`none-${name}`, display:'please select an option', disabled: hasChanged, isNoneOption: true, ...noneOption})
	}

	if(!selectedOption && optionsCopy?.length > 0) {
		// select noneOption (if present) >> select options[0]
		selectedOption = optionsCopy[0].value;
	}
	

	let selectedIsDisabled = optionsCopy.length <= 1;
	/* optionsCopy.forEach(option => {
		if(option.value === selectedOption){
			selectedIsDisabled = option?.isNoneOption ? true : option.disabled;
			return;
		}
	})  */


	const handleOnChange = (e) => {
		// check if new selected option is disabled
		/* const newSelectedOption = options.find(option => option.value === e.target.value)
		if(!newSelectedOption.disabled) {
		} */
		console.log('change')
		setHasChanged(true)
		onChange(e);
	}


	return (
	<div className={`${error ? styles.hasError: ''}`}>
		<label className={`${styles.label} ${styles.selectLabel}`} htmlFor={name}>{label}{required ? '*' : ''}</label>

		{optionsCopy.length === 1 &&
			<p key={optionsCopy[0].value} className={`${styles.select} ${styles.fakeSelect}`}>{optionsCopy[0].value}</p>
		}

		<select name={name} value={selectedOption} id={name} className={`${styles.select} ${optionsCopy.length <= 1 ? styles.hiddenSelect: ''}`} onChange={handleOnChange} onFocus={(e) => {console.log('open'); onFocus(e)}} onBlur={() => console.log('close')} disabled={optionsCopy.length === 1}>
			{optionsCopy.map((option, id) => <option key={option.value} className={`${styles.option} ${option?.isNoneOption || option.disabled ? styles.disabledOption : ''}`} value={option.value} disabled={option.disabled}>
				{`${option.display}${option.disabled ? disabledSuffix : ''}`}
				{(option?.extraDisplay && option.value !== selectedOption)  && option.extraDisplay}
			</option>)}
		</select>

		{error !== undefined && <span className={`${styles.message} ${styles.errorMessage}`}>{error}</span>}
	</div>
	)
}

export default InputSelectControlled;