import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../css/CheckoutForm.module.css';
import { useCart } from '../helpers/useCart';
import { CartContext } from '../helpers/useCartContext';
import GeneralButton from './GeneralButton';
import { InputFakeRadioButton } from './InputFakeRadioButton';
import { AuthStateContext } from '../helpers/authContext';

const CheckoutForm = ({className}) => {


	const {userData, jwt} = useContext(AuthStateContext);
	const cart = useContext(CartContext);
	const navigate = useNavigate();
	const addressOutline = useRef(null);
	const [paymentHeight, setPaymentHeight] = useState('');
	useEffect(() => {
		if(addressOutline.current) {
			setPaymentHeight(addressOutline.current.offsetHeight);
			console.log('UseEffect');
			console.log(addressOutline.current.offsetHeight);
		}
	} ,[]);

	const addressKeys = {
		country: ['companyCountry', 'shippingCountry'],
		city: ['companyCity', 'shippingCity'],
		postalCode: ['companyPostalCode', 'shippingPostalCode'],
		streetNameAndNumber: ['companyStreetNameAndNumber', 'shippingStreetNameAndNumber'],
		addressAddition: ['companyAddressAddition', 'shippingAddressAddition']
	}

	const address = {};
	Object.entries(addressKeys).forEach(entry => { 
		const [key, keysToUse] = entry
		const companyOrShipping =  userData.isCompanyAddressSameAsShippingAddress ? 0 : 1;
		address[key] = userData[keysToUse[companyOrShipping]];
	})
	

	const handleCheckout = async (e) => {

		const url = `${process.env.REACT_APP_STRAPI_URL}/api/order/user_createOrderFromCartItems`;

		const postResponse = await fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${jwt}`
			},
		});

		if(postResponse.ok) {
			const result = await postResponse.json();
			if(result?.action === 'redirect') {
				navigate(result.to);
				cart.refetchCartItems();
			}
		} else {
			
			console.log(`error: Something went wrong with when calling ${url}`) // I need a generic error handler for any page
			// General errors should be displayed as a popup window at the bottom of the screen (like google does in google.account)
			// Input field errors should be displayed at the appropriate location in DOM 
		}
	}

	console.log()
	return (
		<section className={`${styles.card} ${className}`}>
			<h2 className={styles.checkoutTitle}>Checkout</h2>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>Address</h3>
				<div className={styles.sectionOutline} ref={addressOutline}>
					<InputFakeRadioButton/>
					<div className={styles.radioButtonFake}></div>
					<div className={styles.sectionContent}>
						<p>{`${address.streetNameAndNumber}${address?.addressAddition ? (', ' + address.addressAddition) : ''}`}</p>
						<p>{`${address.city} ${address.postalCode}, ${address.country.name} ${address.country.flagEmoji}`}</p>
					</div>
				</div>
				<Link to='/account?tab=shipping' className={styles.addressChange}>change</Link>
			</section>

			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>Payment method</h3>
				<div className={styles.sectionOutline} style={{minHeight: `${paymentHeight}px`}}>
					<InputFakeRadioButton/>
					<div className={styles.radioButtonFake}></div>
					<p className={styles.sectionContent}>Receive payment invoice through email</p>
				</div>
				<p className={styles.paymentBelow}>* order will be dispatched when payment is received by Belpo</p>
			</section>
				
			<div className={styles.finishButtonWrapper}>
				<GeneralButton className={styles.finishButton} size={1} onClick={handleCheckout}>Finish checkout</GeneralButton>
			</div>

		</section>
	)

}

export default CheckoutForm;