import { Link } from 'react-router-dom'
import styles from '../css/CardTemplatePage.module.css'
import Page from './Page'

const CardTemplatePage = ({linkText, linkUrl, children}) => {
	linkText ??= 'Back to sign in';
	linkUrl ??= '/signin';

	return (
		<Page bHasNavBar={false} pageWidth='450px' contentClassName={styles.page}>
			<header className={styles.header}>
				<div className={styles.left}>
					<span className={styles.belpo}>Belpo</span>
					<span className={styles.b2b}>B2b portal</span>
				</div>
				<Link to={linkUrl} className={styles.right}>
					<span>{linkText}</span>
				</Link>
			</header>
			{children}
		</Page>
	)
}

export default CardTemplatePage