import useFetch from '../helpers/useFetch.js';
import { calculateFinalPrice, getAllUniqueColors, getAllUniqueSizes, getDefaultVariation, hoursBetween, prependServerURL } from '../helpers/helper.js';
import {useContext, useMemo, useState} from 'react';
import Page from './Page.js';
import CategoryFilter from './CategoryFilter.js';
import ProductThumbnailMaster from './ProductThumbnailMaster.js';
import { useSearchParams } from 'react-router-dom';



import styles from '../css/ProductsMasterPage.module.css';
import usePost from '../helpers/usePost.js';
import { AuthStateContext } from '../helpers/authContext.js';

const ProductsMasterPage = () => {

	const {userData} = useContext(AuthStateContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const [refetchProducts, setRefetchProducts] = useState(0); // trigger bodyMemo to recompute
	const myUrl = new URL(window.location);
	myUrl.searchParams.delete('category');
	searchParams.getAll('category').forEach(category => {
		myUrl.searchParams.append('category', category);
	});
	window.history.pushState({}, "", myUrl);
	const params = [];
	for(let entry of searchParams.entries()) {
		params.push(entry);
	}
	

	// 1. Consume params
	const activeCategories = searchParams.getAll('category')
	const toggleCategory = (category) => {
		setSearchParams(old => {
			const categories = old?.getAll('category');

			if(categories?.includes(category)) {
				// get categories as array of values ['cat1','cat2']
				// remove category
				categories.splice(categories.indexOf(category),1);
				// clear old
				old.delete('category')
				// old.append all left 
				categories.forEach(cat => old.append('category', cat))
			} else {
				old.append('category', category);
			}
			setRefetchProducts(refetchProducts + 1);
		})
	}

	// 2. set params en rerender  

	// 1. Title
	// 2. Tags overview
	// 3. Products
	const qs = require('qs');
	// ___________________________________________________________________________________ Categories (Fetch, selectedStates state)
	let query = qs.stringify({
	fields: ['id', 'name'],
		sort: 'ranking:asc'
	});

	let url = `${process.env.REACT_APP_STRAPI_URL}/api/categories?${query}`;
	const [isLoadingCategories, fetchedCategoriesData, categoriesErrors ] = useFetch(url, ['Public'] );
	
	let categoriesData = [];
	


	if(fetchedCategoriesData) {
		categoriesData = fetchedCategoriesData.data;
	}

	// ________________________________________________________________________________ Products based on Category filter
	
	url = `${process.env.REACT_APP_STRAPI_URL}/api/products/customFind`;
	const bodyMemo = useMemo(() => {
		if (activeCategories?.length === 0) {
			return {}
		} else {
			return {
				categoryNames: activeCategories
			}
		}
	}, [refetchProducts]);

	const [isLoadingProducts, fetchedProducts, productsErrors] = usePost(url, bodyMemo);

	
	let productsJsx = [];
	if(fetchedProducts) {
		console.log({fetchedProducts});
		// if you forget to assing a variation to a product, said product cannot be displayed.
		const invalidProducts = fetchedProducts.filter(product => product.variations.length === 0 || !product.thumbnailImage);
		console.log('These products should be changed in the backend!!');
		console.log({invalidProducts});
		const validProducts = fetchedProducts.filter(product => product.variations.length > 0);
		productsJsx = validProducts.map(data => {
			let thumbnailUrl = data.thumbnailImage === null ? null : prependServerURL(data.thumbnailImage?.formats?.small?.url ??  data.thumbnailImage.url);
			const hourseElapsed =  hoursBetween(data.createdAt);
			const isNew = hourseElapsed <= process.env.REACT_APP_HOURS_NEW;
			
			const defaultVariation = getDefaultVariation(data.variations);
			const nbColorVariations = getAllUniqueColors(data.variations).length;
			const nbSizeVariations = getAllUniqueSizes(data.variations).length;

			const priceObject = defaultVariation.basePrice === undefined ? undefined: calculateFinalPrice(defaultVariation.basePrice,defaultVariation.baseDiscount, defaultVariation.minimumAmount, defaultVariation.priceDropQuantity, defaultVariation.priceAfterPriceDrop);
			
			return (
				<li key={`thumbnail-${data.id}`}>
					<ProductThumbnailMaster
						title={data.name}
						thumbnailUrl={thumbnailUrl}
						priceObject={priceObject}
						soldPer={data.soldInPacksOf}
						bShowSoldPer={true}
						nbColors={nbColorVariations}
						nbSizes={nbSizeVariations}
						to={`/products/${data.id}`}
						isNew={isNew}
						isFeatured={data.isFeatured}
					/>
				</li>
			);
		})
	}
	
	


	return (
		<Page bHasNavBar={true}>
			<header className={styles.header}>
				<h1 className={styles.title}>Products</h1>
				<CategoryFilter selectedCategories={activeCategories} toggleCategoryByName={(category) => toggleCategory(category)} categories={categoriesData}/>
			</header>
			
			<ul className={styles.products}style={{listStyle: 'none', padding: '0'}}>
				{productsJsx}
			</ul>

		</Page>
	)
}

export default ProductsMasterPage;