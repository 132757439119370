import { dataFromForm } from '../helpers/helper'
import InputText from './InputText'
import styles from '../css/SignUpPage.module.css'
import InputCheckbox from './InputCheckbox';
import { useRef, useState } from 'react';
import GeneralButton from './GeneralButton';
import InputSelectControlled from './InputSelectControlled';

const SignUpStep3 = ({className, reference, getErrorJsx, triggerSubmit, resetSingleValidation, countryOptions}) => {
	const [selectedCountry, setSelectedCountry] = useState();
	const completeButton = useRef();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const nextButton = useRef();
	const [skipShippingAddress, setSkipShippingAddress] = useState(false);

	const fieldNames = ['companyCountry', 'companyCity', 'companyPostalCode', 'companyStreetNameAndNumber', 'companyAddressAddition', 'isCompanyAddressSameAsShippingAddress'];
	const handleCheckboxChange = (e) => {
		resetSingleValidation(e);
		setSkipShippingAddress(e.target.checked);
	}

	const onSubmit = async (e) => {
		setIsSubmitting(true)
		e.preventDefault();
		const data = dataFromForm(e.target);
		await triggerSubmit(data);
		setIsSubmitting(true)
		/* 
		if(skipShippingAddress) {
			//triggerComplete()
			console.log("DATA");
			console.log(data);
	
		} else {
			const data = dataFromForm(e.target);
			console.log("DATA");
			console.log(data);
			triggerSubmit(data);
		} */
	}

	const handleClick = (e) => {
		nextButton.current.click();
	};
 
	return (
		<div className={className} ref={reference}>
			<h2 className={styles.title}>Company Address</h2>
			<form onSubmit={onSubmit}>
				<InputSelectControlled label="Country" required options={countryOptions} name={fieldNames[0]} error={getErrorJsx(fieldNames[0])} noneOption={{value: '-1', display: 'Please select your country'}} selectedOption={selectedCountry} onFocus={resetSingleValidation} onChange={e => setSelectedCountry(e.target.value)}></InputSelectControlled>
				<div style={{display: 'flex', justifyContent:'space-between', gap:'var(--sp-2'}}>
					<InputText name={fieldNames[1]} error={getErrorJsx(fieldNames[1])} onFocus={resetSingleValidation}>City*</InputText>
					<InputText name={fieldNames[2]} error={getErrorJsx(fieldNames[2])} onFocus={resetSingleValidation}>Postal code*</InputText>
				</div>
				<InputText name={fieldNames[3]} error={getErrorJsx(fieldNames[3])} onFocus={resetSingleValidation} >Street name &amp; number*</InputText>
				<InputText name={fieldNames[4]} error={getErrorJsx(fieldNames[4])} onFocus={resetSingleValidation}>Appartement, suite, etc.</InputText>
				<InputCheckbox name={fieldNames[5]} error={getErrorJsx(fieldNames[5])} onChange={handleCheckboxChange}>Use this address as shipping address</InputCheckbox>
				{skipShippingAddress ? <GeneralButton onClick={handleClick}>Complete</GeneralButton> : <GeneralButton onClick={handleClick}>Shipping address</GeneralButton>}
				<input type='submit' style={{display: 'none'}} ref={nextButton}></input>
			</form>
		</div>
	)

};

export default SignUpStep3;


// Check what is sent to server on submit -> Do validation on server