import { useNavigate } from 'react-router-dom';

import { useContext } from 'react';
import { AuthStateContext } from '../helpers/authContext';

const RequireAuth = ({children }) => {

	const navigate = useNavigate();
	const { userData } = useContext(AuthStateContext);
	if(!userData) {
		navigate('/');
		return <div>redirecting to home page</div>
	} 
	return children;
};

export default RequireAuth;

// Source: https://ui.dev/react-router-protected-routes-authentication