import styles from '../css/SignUpRequestConfirmationPage.module.css';
import composedStyles from '../css/messageCardPage.module.css';
import CardTemplatePage from './CardTempatePage'
import Page from './Page'

const SignUpRequestConfirmationPage = () => {
	return (
		<CardTemplatePage>
			<div className={composedStyles.container}>
				<h1 className={`${composedStyles.title} ${styles.title}`}>Confirm your email.</h1>
				<p className={composedStyles.emoji}>📬</p>
				<p className={composedStyles.main}>Confirmation email has been sent.</p>
				<p className={composedStyles.sub}>Please click the link in the email to confirm your email address.</p>
				<p className={composedStyles.tiny}>You can close this page.</p>
			</div>
		</CardTemplatePage>
	)
}

export default SignUpRequestConfirmationPage