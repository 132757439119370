import styles from '../css/SignUpPage.module.css';
import { Link } from 'react-router-dom';
import InputText from './InputText.js';
import InputCheckbox from './InputCheckbox';
import GeneralButton from './GeneralButton';
import { useRef, useState } from 'react';
import { dataFromForm } from '../helpers/helper';

const SignUpStep1 = ({className, reference, getErrorJsx, triggerSubmit, resetSingleValidation, disabled}) => {

	const $hiddenSubmit = useRef(null);

	const submitForm = (e) => {
		if($hiddenSubmit) {
			$hiddenSubmit.current.click();
		}
	}
	

	const onSubmit = (e) => {
		e.preventDefault();
		console.log(e.target);
		const formData = dataFromForm(e.target);
		console.log(formData);

		formData.confirmPassword = [formData?.password, formData?.confirmPassword]
		console.log(JSON.stringify(formData));

		triggerSubmit(formData);
	}

	const fieldNames = [
		'email',
		'password',
		'confirmPassword',
		'consent'
	];

	// need to reuse this in all pages -> shouldn't be part of this component
	




	return (
		<div className={`${className}`} ref={reference}>
		<h1 className={styles.title}>Sign up</h1>
		<form onSubmit={onSubmit}>
			<InputText disabled={disabled} value='' name={fieldNames[0]} error={getErrorJsx(fieldNames[0])} onFocus={resetSingleValidation} defaultValue=''>Email address*</InputText>
			<InputText disabled={disabled} type='password' value='' name={fieldNames[1]} error={getErrorJsx(fieldNames[1])} onFocus={resetSingleValidation} defaultValue=''>Password*</InputText>
			<InputText disabled={disabled} type='password' value='' name={fieldNames[2]} error={getErrorJsx(fieldNames[2])} onFocus={resetSingleValidation} defaultValue=''>Confirm Password*</InputText>
			<InputCheckbox disabled={disabled} name={fieldNames[3]} error={getErrorJsx(fieldNames[3])} onChange={resetSingleValidation}>I have read the <a>terms and conditions*</a></InputCheckbox>
			<GeneralButton size={1} onClick={submitForm}>Sign up</GeneralButton>
			<input disabled={disabled} type='submit' style={{display: 'none'}} ref={$hiddenSubmit}></input>
		</form>
		</div>
	)
}
export default SignUpStep1;