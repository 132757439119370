import { Routes, Route, useNavigate, json, redirect } from 'react-router-dom';
import RequireAuth from './RequireAuth.js';
import RequireAdmin from './RequireAdmin.js';
import RequireLoggedOut from '../authentication/RequireLoggedOut.js';
import { useEffect, useMemo, useState } from 'react';

import '../css/var.module.css';

import SignUpPage from './SignUpPage.js';
import SignInPage from './SignInPage.js';
import AccountPage from './AccountPage.js';
import HomePage from './HomePage.js';
import ProductsMasterPage from './ProductsMasterPage.js';
import {
	historyKey,
} from '../helpers/helper.js';
import { CartContext } from '../helpers/useCartContext.js';
import CheckoutPage from './CheckoutPage.js';
import NewDetailPage from './NewDetailPage.js';
import { useCart } from '../helpers/useCart.js';
import NewCartPage from './NewCartPage.js';
import { OrderConfirmationPage } from './OrderConfirmationPage.js';
import UpdateApprovalStatus from './UpdateApprovalPage.js';
import { ForgotPasswordPage } from './ForgotPasswordPage.js';
import { ResetPasswordPage } from './ResetPasswordPage.js';
import SignUpRequestConfirmationPage from './SignUpRequestConfirmationPage.js';
import useFetch from '../helpers/useFetch.js';
import ProductGallery from './ProductGallery.js';
import RequireApproved from '../authentication/RequireApproved.js';

const qs = require('qs');

function App() {

	const [loginData, setLoginData] = useState(null); // There are multiple states
	// 1. Uninitialized, didn't even try to get the login data from storage => undefined
	// 2. Initialized and not logged in										=> null
	// 3. Initialized and logged in											=> Object
	const [
		cartItems,
		refetchCartItems,
		addCartItemsClientSide,
		changeCartItemsClient,
		updateAmountServer,
	] = useCart(loginData);

	// ____________________________________________________________________________________ View History in local storage
	const addViewHistoryId = (newProductId) => {
		// get view history ids
		// check if new one already in there
		// If yes remove it
		// add new Id

		const viewHistoryIdsJson = window.localStorage.getItem(historyKey);
		const prevIds = viewHistoryIdsJson ? JSON.parse(viewHistoryIdsJson) : [];

		const index = prevIds.indexOf(newProductId);
		if (index > -1) {
			prevIds.splice(index, 1);
		}
		prevIds.push(newProductId);
		window.localStorage.setItem(historyKey, JSON.stringify(prevIds));
	};

	//logout();

	// ____________________________________________________________________________________ Home page blocks (stored in db)
	const query = qs.stringify({
		fields: ['id'],
		populate: {
			Blocks: {
				on: {
					'building-blocks.filteredproducts': {
						fields: ['TitleOnWebsite', 'Filter'],
					},
					'building-blocks.productsofcategory': {
						populate: {
							product: {
								fields: ['id', 'name'],
							},
						},
					},
				},
			},
		},
	});
	const url = `${process.env.REACT_APP_STRAPI_URL}/api/home-page?${query}`;
	const [isLoadingBlocks, blocksResponse] = useFetch(url);

	const homeBlocksJsxMemo = useMemo(() => {
		let fetchedComponentsJsx = <></>;
		if (blocksResponse?.data.attributes.Blocks.length > 1) {
			const blocks = blocksResponse.data.attributes.Blocks;
			fetchedComponentsJsx = blocks.map((block) => {
				// 1. Gallery of products (Filtered version) (new/featured/recently viewed/...)
				if (block?.Filter) {
					// hard coded enum values. These must match the ones on strapi backend
					const title = block?.TitleOnWebsite
						? block?.titleOnWebsite
						: block.Filter;
					return (
						<ProductGallery
							key={`product-gallery-filter-${title}`}
							title={title}
							queryType={block.Filter}
						/>
					);
				}

				// 2. Gallery of products (Based on category version)
				if (block?.product) {
					const categoryName = block.product.data.attributes.name;
					return (
						<ProductGallery
							key={`product-gallery-category-${categoryName}`}
							title={categoryName}
							queryType={`category:${categoryName}`}
						/>
					);
				}
			});
			return fetchedComponentsJsx;
		}
	}, [blocksResponse, loginData]);

	// _______________________________________________________________________________________________ ROUTES
	return (
		<CartContext.Provider
			value={{
				cartItems,
				refetchCartItems,
				addCartItemsClientSide,
				changeCartItemsClient,
				updateAmountServer,
			}}
		>
			<Routes>
				<Route
					path="/"
					element={<HomePage fetchedComponentsJsx={homeBlocksJsxMemo} />}
				/>
				<Route path="/products" element={<ProductsMasterPage />} />
				<Route
					path="/products/:id"
					element={<NewDetailPage addViewHistoryId={addViewHistoryId} />}
				/>
				<Route
					path="/cart"
					element={
						<RequireAuth>
							<NewCartPage />
						</RequireAuth>
					}
				/>
				<Route
					path="/checkout"
					element={
						<RequireApproved>
							<CheckoutPage />
						</RequireApproved>
					}
				/>
				<Route
					path="/thankYouForYourOrder"
					element={
						<RequireApproved>
							<OrderConfirmationPage />
						</RequireApproved>
					}
				/>
				<Route
					path="/signin"
					element={
						<RequireLoggedOut>
							<SignInPage />
						</RequireLoggedOut>
					}
				/>
				<Route
					path="/signup"
					element={
						<RequireLoggedOut>
							<SignUpPage />
						</RequireLoggedOut>
					}
				/>
				<Route path="/forgotpassword" element={<ForgotPasswordPage />} />
				<Route path="/resetpassword" element={<ResetPasswordPage />} />
				<Route
					path="/account"
					element={
						<RequireAuth>
							<AccountPage />
						</RequireAuth>
					}
				/>
				<Route
					path="/pleaseconfirmemail"
					element={<SignUpRequestConfirmationPage />}
				/>
				<Route
					path="/updateApprovalStatus"
					element={
						<RequireAdmin>
							<UpdateApprovalStatus />
						</RequireAdmin>
					}
				/>
			</Routes>
		</CartContext.Provider>
	);
}

export default App;
