import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styles from '../css/UpdateApprovalStatus.module.css';
import GeneralButton from './GeneralButton';
import Page from './Page'

const UpdateApprovalStatus = ({loginData}) => {
	
	let params = new URLSearchParams(window.location.search);
	let targetUserId = params.get('targetUserId');
	let approvalStatusId = params.get('approvalStatusId');
	const location = useLocation();
	
	if((!targetUserId  || !approvalStatusId) && false) {
		<Navigate to="/" replace state={{path: location.pathname}}/>
	}

	const [isUpdating, setIsUpdating] = useState(false);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const asyncFetch = async () => {
			setIsUpdating(true);
			setError(null);
			const url = `${process.env.REACT_APP_STRAPI_URL}/api/users-permissions/users/updateApprovalStatus`;
			const response = await fetch(url, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${loginData.jwt}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({targetUserId, approvalStatusId})
			});

			setIsUpdating(false);
			const result = await response.json();
			if(response.ok && result?.action === 'success') {
				console.log(result);
				setData(result);

			} else {
				console.log(response);
				setError('Something went wrong while fetching');
			}
		}

		if(targetUserId && approvalStatusId){
			asyncFetch();
		} else {
			setError('targetUserId and/or approvalStatusId are not present in the url params');
		}


	}, [targetUserId, approvalStatusId])
	
	return (
	<Page bHasNavBar={true} loginData={loginData}>
		<div className={styles.card}>
			<h1 className={styles.title}>Approval status updater tool</h1>
			<div className={styles.content}>
				<div>
					{isUpdating && <p className={styles.process}>Loading...</p>}
					{!isUpdating && data?.action === 'success' && <div className={styles.updateInfoWrapper}>
						<p className={styles.process}>Approval status has been updated.</p>
						<div className={styles.updateCompany}>
							<p>{data.targetUserCompanyName}</p>
							<p>{data.targetUserEmail}</p>
						</div>
						<div className={styles.statusUpdateWrapper}>
							<p className={styles.statusUpdateLabel}>New status</p> 
							<p><strong>{data.newStatus}</strong></p>
						</div>
					</div>}
					{ error && <div className={styles.updateInfoWrapper}>
						<p className={styles.process}>Waiting for you to fix error</p>
						<p className={styles.error}>{error}</p>
					</div>
					}

					<div style={{display: 'flex', justifyContent: 'center'}}>
						<GeneralButton to='/' size={2}>Home</GeneralButton>
					</div>

					<p className={styles.bottomNote}>This page is secure. This page is only accessible to admins.<br/>If you are not an admin on this page, you will not be able to do anything on the server.</p>
				</div>
			</div>
		</div>

	</Page>
	);

}

export default UpdateApprovalStatus;