import { useContext, useEffect, useRef, useState } from 'react'
import { AuthStateContext } from './authContext';

const useFetch = (url, roleBlacklist, roleWhitelist) => {
	const { userData, jwt } = useContext(AuthStateContext);
	roleBlacklist ??= [];
	roleWhitelist ??= [];

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);
	const [errors, setErrors] = useState(false);
	const [fetchCount, setFetchCount] = useState(0);
	const fetchId = useRef(0);

	useEffect(() => {

		
		const asyncFetch = async () => {
			setIsLoading(true);
			fetchId.current += 1;
			const currentFetchId = fetchId.current;
			

			if(url?.includes('download-items')) {
				console.log('-------Loading is set to TRUE ----------')
			}
			let options = {
				method: 'GET',
				headers: {
					"Content-Type" : "application/json",
				}
			};

			if(jwt) {
				options.headers.Authorization = `Bearer ${jwt}`;
			}
			
			
			if(url?.includes('download-items')) {
				console.log('-------Loading:: before the FETCH ----------')
			}
			const response = await fetch(url, options);
			console.log('-------Loading:: Between FETCH and RESPONSE.JSON() ----------')
			console.log('response')
			if(response.ok) {

			} else {
				console.log('response was not OK!!')
				console.log(response);
				
			}		


			let result = {};
			try {
				
				result = await response.json();
				
			} catch (error) {
				console.log(`useFetch::ERROR response.json() failed! ${url}`);
				console.log(error);
			}



			// Aborting fetches that are not resolved before the next one has launched.
			// Aborting fetches that are already out of date
			if(currentFetchId !== fetchId.current) {
				console.log('My fetch was aborted because newer one is already in progress.');
				return;
			}			
			
			setIsLoading(false);
			if(url?.includes('download-items')) {
				console.log('-------Loading is set to FALSE ----------')

				console.log('UseFetch::INFO - Response was ok: ' + response.ok)
				console.log('UseFetch::INFO - Data vvv')
				console.log(result);
			}

			if(response.ok) {
				setData(result);
				setErrors(false);
				
			} else {
				setData(null);
				setErrors(result.error); 
			}
		};



		if(url !== false ) {
			if(roleWhitelist.length > 0) {
				if(!roleWhitelist.includes(userData?.role?.name ?? 'Public')) {
					return;
				}
			}

			if(roleBlacklist.length > 0) {
				if(roleBlacklist.includes(userData?.role?.name ?? 'Public')) {
					return;
				}
			}
			asyncFetch();
		}
	}, [url, fetchCount, userData]);


	const refetch = () => {
		setFetchCount(oldFetchCount => oldFetchCount + 1);
	};

	return [isLoading, data, errors, refetch, fetchCount];
}

export default useFetch;