import { formatCurrency, getVariationData, missingImageUrl } from '../helpers/helper';
import styles from '../css/CartItemRow.module.css';
import GeneralNumberFieldUncontrolled from './GeneralNumberFieldUncontrolled';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { ExclusiveTaxSpan } from './ExclusiveTaxSpan';

const CartItemRow = ({title, productId, variationData, imageUrl, basePrice, discountPercentage, finalPrice, amount, minimumAmount, deleteFunction, updateAmountFunction, triggerUpdateDatabase, triggerAltering, clientIsModifying, isFrozen, isDirty}) => {
	title ??= 'title';
	imageUrl ??= missingImageUrl;
	basePrice ??= 9999;
	amount ??= 1;
	minimumAmount ??= 1;
	deleteFunction ??= () => {};
	updateAmountFunction ??= () => {};

	const numberFieldRef = useRef(null);
	const triggerUpdateDatabaseRef = useRef(null);
	triggerUpdateDatabaseRef.current = triggerUpdateDatabase; // for each rerender

	//console.log('rerender cart item');
	//triggerUpdateDatabase(); // this works correctly. Meaning that adding the eventlistener is the issue.

	
	let variationStrings = [];
	Object.entries(variationData).forEach(entry => {
		const [key, value] = entry;
		if(value) {
			variationStrings.push(`${key}: ${value}`);
		}
	})

	const variationDataJsx = Object.entries(variationData).map(entry => <span key={entry[0]}className={styles.belowTitle}>{entry[0]}: {entry[1]}</span>)


	const handleOnClickInputFinish = () => {
		triggerUpdateDatabaseRef.current();
	}

	const linkUrl = `/products/${productId}`;
	return (
	<article className={`${styles.row} ${isDirty ? styles.isModifying : ''} ${isFrozen ? styles.isFrozen : ''}`}>
		<Link className={styles.imageWrapper}to={linkUrl}><img className={styles.image} src={imageUrl}/></Link>
		<header className={styles.header}>
			<Link to={linkUrl}><h2 className={styles.title}>{title}</h2></Link>
			<div className={styles.variationData}>
				{variationDataJsx}
				<span className={styles.priceIndividual}>
					{basePrice !== finalPrice && <span className={styles.priceIndividualOld}>{formatCurrency(basePrice)}</span>}
					<span>{formatCurrency(finalPrice)} each</span>
					<ExclusiveTaxSpan style={{display: 'block'}}/>
					
				</span>
			</div>
			{/* <GeneralNumberFieldUncontrolled value={amount} className={styles.numberField} onChange={updateAmountFunction} ref={numberFieldRef} onEnter={triggerUpdateDatabaseRef.current} disabled={isFrozen}/> */}
			<GeneralNumberFieldUncontrolled value={amount} min={minimumAmount} className={styles.numberField} onChange={updateAmountFunction} ref={numberFieldRef} onClickInputFinish={() => isDirty ? handleOnClickInputFinish() : ''} onTextInputFinish={() => isDirty ? triggerUpdateDatabaseRef.current() : ''} disabled={isFrozen}/>
		</header>
		<div className={styles.priceBlock}>
			{discountPercentage > 0 && <span className={styles.discount}>-{discountPercentage}%</span>}
			<span className={styles.totalPrice}>{formatCurrency(amount * finalPrice)}</span>
			{discountPercentage > 0 && <span className={styles.originalPrice}>{formatCurrency(amount * basePrice)}</span>}
			<ExclusiveTaxSpan/>
		</div>
		<div className={styles.removeBlock}>
			<button className={styles.remove} onClick={() => {deleteFunction()}} disabled={isFrozen}>
				x
			</button>
		</div>
	</article>
	);
}

export default CartItemRow;