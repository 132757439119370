import { useRef, useState } from 'react';
import styles from '../css/SignUpPage.module.css';
import { dataFromForm } from '../helpers/helper';
import GeneralButton from './GeneralButton';
import InputText from './InputText';
import InputSelectControlled from './InputSelectControlled';

const SignUpStep4 = ({className, reference, getErrorJsx, triggerSubmit, resetSingleValidation, disabled, countryOptions}) => {
	const submit = useRef();
	const [isLoading, setIsLoading] = useState(false);
	const onSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		await triggerSubmit(dataFromForm(e.target));
		setIsLoading(false);
	}
	const fieldNames = ['shippingCountry', 'shippingCity', 'shippingPostalCode', 'shippingStreetNameAndNumber', 'shippingAddressAddition'];

	const [selectedCountry, setSelectedCountry] = useState();

	return (
		<div className={className} ref={reference}>
			<h2 className={styles.title}>Shipping address</h2>
			<form onSubmit={onSubmit}>
				<InputSelectControlled label="Shipping country" required options={countryOptions} name={fieldNames[0]} error={getErrorJsx(fieldNames[0])} noneOption={{value:'-1', display: 'Please select your country'}} selectedOption={selectedCountry} onFocus={resetSingleValidation} onChange={e => setSelectedCountry(e.target.value)}></InputSelectControlled>
				<div style={{display: 'flex', justifyContent:'space-between', gap:'var(--sp-2'}}>
					<InputText disabled={disabled} name={fieldNames[1]} error={getErrorJsx(fieldNames[1])} onFocus={resetSingleValidation}>Shipping City*</InputText>
					<InputText disabled={disabled} name={fieldNames[2]} error={getErrorJsx(fieldNames[2])} onFocus={resetSingleValidation}>Shipping postal code*</InputText>
				</div>
				<InputText disabled={disabled} name={fieldNames[3]} error={getErrorJsx(fieldNames[3])} onFocus={resetSingleValidation}>Shipping street name &amp; number*</InputText>
				<InputText disabled={disabled} name={fieldNames[4]} error={getErrorJsx(fieldNames[4])} onFocus={resetSingleValidation}>Shipping appartement, suite, etc.</InputText>
				<GeneralButton disabled={isLoading} onClick={() => submit.current.click()}>Complete</GeneralButton>
				<input type='submit' style={{display: 'none'} }ref={submit}></input>
			</form>

		</div>
	)
}

export default SignUpStep4;