import { useContext } from 'react';
import styles from '../css/ProductGalleryItem.module.css';
import { formatCurrency,formatSoldPer,calculateFinalPrice, isApproved } from '../helpers/helper';
import { Bubble } from './Bubble';
import { BubblesContainer } from './BubblesContainer';
import HiddenPrice from './HiddenPrice';
import { AuthStateContext } from '../helpers/authContext';

const ProductGalleryItem = ({title, basePrice, baseDiscount, priceDropQuantity, priceAfterPriceDrop, minimumAmount, soldPer, imageUrl, uniqueColors, uniqueSizes, small, loginData}) => {
	const priceIsHidden = basePrice == undefined;
	const {userData} = useContext(AuthStateContext);

	let variationInfoJsx = null;
	if(uniqueColors?.length > 1 || uniqueSizes?.length > 1 ) {;

		variationInfoJsx = <>
			{uniqueColors?.length > 1 && <p>{uniqueColors.length} Color{uniqueColors.length > 1 ? 's' : ''}</p>}
			{uniqueSizes?.length > 1 && <p>{uniqueSizes.length} Size{uniqueSizes.length > 1 ? 's' : ''}</p>}
		</>
	}


	let priceJsx = <></>;
	let priceObject = {};
	if(!priceIsHidden){
		priceObject = calculateFinalPrice(basePrice, baseDiscount, minimumAmount, priceDropQuantity, priceAfterPriceDrop);
		priceJsx = (
		<>
			<div className={styles.price}>
					{priceObject.discountApplied && <span className={styles.oldPrice}>{formatCurrency(priceObject.basePrice)}</span>}
					<div className={styles.finalPrice}>
						<span className={priceObject.discountApplied ? styles.discountedPrice : ''}>{formatCurrency(priceObject.finalPrice)}</span>
						<span className={styles.soldPer}>/{formatSoldPer(soldPer)}</span>
					</div>
				</div>
			<span className={styles.exclusiveTax}>Excl. tax</span>

		</>
		);
	} else {
		priceJsx = <HiddenPrice className={styles.priceReplacement} isLoggedIn={userData} short/>;
	}

	return (
	<article className={styles.container}>
		<div style={{position: 'relative'}} className={styles.imageWrapper}>
			<img  className={styles.image} src={imageUrl}/>
			{!priceIsHidden &&  priceObject.discountApplied && 
			<BubblesContainer>
				<Bubble>-{priceObject.discountPercentage}%</Bubble>
			</BubblesContainer>
			}
		</div>
		<header className={styles.bottom}>
			{ variationInfoJsx && <div className={styles.variationsInfo}>{variationInfoJsx}</div>}
			<h3 className={`${priceIsHidden ? styles.titleHiddenPrice : styles.title}`}>{title}</h3>	
			{priceJsx} 
		</header>
	</article>
	);

}

export default ProductGalleryItem;