import { Navigate, useLocation } from 'react-router-dom';

const RequireAdmin = ({ loginData, children }) => {
	const location = useLocation();
	if(loginData === undefined) {
		// hold
		return <div>Loggin in</div>;
	} else if (loginData?.user?.role?.name === 'Admin') {
		// render children
		return children;
	} else if (loginData === null) {
		// Login initialized but no login found / login denied
		return <Navigate to="/" replace state={{path: location.pathname}}/>
	} else {
		// user is not an admin (client side, not secure)
		return <Navigate to="/" replace state={{path: location.pathname}}/>
	}
};

export default RequireAdmin;

// Source: https://ui.dev/react-router-protected-routes-authentication