import { useState, useRef } from 'react';
import styles from '../css/SignUpPage.module.css';
import { dataFromForm } from '../helpers/helper';
import GeneralButton from './GeneralButton';
import InputText from './InputText';


const SignUpStep2 = ({disabled, reference, getErrorJsx, className, triggerSubmit}) => {

	const hiddenSubmit = useRef();

	const onSubmit = (e) => {
		e.preventDefault();
		const formData = dataFromForm(e.target);
		triggerSubmit(formData);
	}

	const fieldNames = ['companyName', 'vat', 'companyWebsite', 'phone'];

	return (
		<form className={className} onSubmit={onSubmit} ref={reference}>
			<h2 className={styles.title}>Company details</h2>
			<InputText name={fieldNames[0]} error={getErrorJsx(fieldNames[0])} disabled={disabled} >Company name*</InputText>
			<InputText name={fieldNames[1]} error={getErrorJsx(fieldNames[1])} disabled={disabled} >VAT*</InputText>
			<InputText name={fieldNames[2]} error={getErrorJsx(fieldNames[2])} disabled={disabled} >Company website(optional)</InputText>
			<InputText name={fieldNames[3]} error={getErrorJsx(fieldNames[3])} disabled={disabled} regex={/^\+*\d*$/}>Telephone/mobile*</InputText>
			<input type="submit" style={{display: 'none'}} ref={hiddenSubmit} disabled={disabled}></input>
			<GeneralButton size={1} onClick={() => hiddenSubmit.current.click()}>Sign up</GeneralButton>

		</form>
	)
}

export default SignUpStep2;