export const isProduction = () => (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development')
export const getJWTName = () => `belpo_jwt_${isProduction() ? 'production' : 'development'}`
export const getUserDataName = () => `belpo_user_data_${isProduction() ? 'production' : 'development'}`
export const storeJWT = (value) => localStorage.setItem(getJWTName(), value);
export const storeUserData = (value) => localStorage.setItem(getUserDataName(), value);
export const readUserData = () => localStorage.getItem(getUserDataName());
export const readJWT = () => localStorage.getItem(getJWTName());
		export const dataFromForm = ($form) => {
	const formData = new FormData($form);
	const data = Object.fromEntries(formData);
	return data;	// returns object of objects that are name/value pairs
}



export const isApproved = (loginData) => {
	return loginData?.approvalStatus?.includes('granted');
}

export const formatCurrency = (price) => price.toLocaleString('en-US', {style:'currency', currency: 'EUR'});
export const formatSoldPer = soldPer => soldPer > 1 ? ` ${soldPer}-pack`:' 1 piece';
export const formatStrapiDate = (strapiDate) => {
	const date = new Date(strapiDate);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year:'numeric',
      hour: 'numeric',
      minute: '2-digit'
    });
}
export const hoursBetween = (strapiTime1, strapiTime2) => {

	let date2 = strapiTime2 ? new Date(strapiTime2) : Date.now();
	let date1 = new Date(strapiTime1);

	if(date2 < date1) {
		const tempDate = date2;
		date2 = date1;
		date1 = tempDate;
	}
	const msElapsed = date2 - date1;
	const msInHours = 3600000;
	const hoursElapsed = msElapsed/msInHours;
	return hoursElapsed;
}


export const missingImageUrl = `${process.env.REACT_APP_STRAPI_URL}/uploads/MISSING_7667a09783.jpg?updated_at=2023-03-23T16:16:22.715Z`;
export const historyKey = 'viewHistoryIds';

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getUserAvatarSrc = (companyName = 'ward van bever') => `https://source.boringavatars.com/marble/120/${companyName.slice(0, companyName.indexOf(' '))}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`
export const calculateFinalPrice = (basePrice, baseDiscount, amount, priceDropQuantity, priceAfterPriceDrop) => {
	amount ??= 1;
	basePrice ??= 0;
	baseDiscount ??= 0;
	priceDropQuantity ??= -1;
	priceAfterPriceDrop ??= -1;

	let result = {basePrice: basePrice, finalPrice: null, priceDropApplied: false, discountApplied: false, discountPercentage: null}
	// 20 
	// 50
	// 18

	let workingPrice = undefined;
	if(priceDropQuantity > 0 && amount >= priceDropQuantity) {
		workingPrice = priceAfterPriceDrop;	// 18
		result.priceDropApplied = true;
	} else {
		workingPrice = basePrice;	// 20
	}

	
	result.finalPrice = workingPrice * (1 - baseDiscount/100); 	// 18 * 0.5 = 9
	const totalDiscount = (basePrice - result.finalPrice) / basePrice;	// 20 - 9 / 20 = 
	if(totalDiscount > 0 ) {
		result.discountApplied = true;
		result.discountPercentage = Math.floor(totalDiscount*100);
	}
	
	return result;
}

export const getDefaultVariation = (allVariations) => {
	// First variation.overrideOtherVariants.inStock > first varition.useAsDefault.inStock > first variation.inStock > first variation (out of stock)
	const overrideOtherVariant = allVariations.find(variationToCheck => variationToCheck.overrideOtherVariants && variationToCheck.inStock);
	if(overrideOtherVariant) return overrideOtherVariant;

	const defaultVariation = allVariations.find(variationToCheck => variationToCheck.useAsDefault && variationToCheck.inStock);
	if(defaultVariation) return defaultVariation;

	const firstInStock = allVariations.find(variationToCheck => variationToCheck.inStock)
	if(firstInStock) return firstInStock;

	return allVariations[0];
}

const getAllAvailableVariations = (allVariations) => {
	// 1. single variation.overrideOthers
	// 2. all instock variations

	const overrideOthersVariation = allVariations.find(variation => variation.overrideOtherVariant && variation.inStock);
	if(overrideOthersVariation) return [overrideOthersVariation];

	const inStockVariations = allVariations.filter(variation => variation.inStock);
	if(inStockVariations) return inStockVariations;

 	return [];
}

export const getAllUniqueColors = (variations) => {
	const uniqueColors = [];
	
	const availableVariations = getAllAvailableVariations(variations); 
	availableVariations.forEach(variation => {
		if(!uniqueColors.includes(variation.colorName)) {
			uniqueColors.push(variation.colorName);
		}
	});

	return uniqueColors;
}

export const getAllUniqueSizes = (variations) => {
	const availableVariations = getAllAvailableVariations(variations); 
	const uniqueSizes = [];
	availableVariations.forEach(variation => {
		if(!uniqueSizes.includes(variation.size)) {
			uniqueSizes.push(variation.size);
		}
	});
	return uniqueSizes;
}

// cartItem.variationData = Json. Only has appropriate fields. If no color/size is specified for the variation, it won't be here as well
export const getDisplayVariationData = (variationDataObject) => {

	let displayVariationData = {};
	 Object.entries(variationDataObject).forEach(entry => {
		let [key, value] = entry;
		if(key === 'colorName') key = 'color';
		displayVariationData[key] = value;
	})

	return displayVariationData;
}


/* ___________________________________________________________________________________ FORM VALIDATION FUNCTIONS */
export const getErrorFromErrorsState = (errorState, fieldName) => {
	return errorState.hasOwnProperty(fieldName) ? errorState[fieldName] : ''
}

export const removeFieldError = (errorsState, setErrorsState, fieldName) => {
	const newErrors = {...errorsState};
	if(newErrors.hasOwnProperty(fieldName)) {
		delete newErrors[fieldName];
		setErrorsState(newErrors)
	}
}

export const prependServerURL = (url, whenInDevelopment = true, whenInProduction = false) => {

	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		
		return `${whenInDevelopment ? process.env.REACT_APP_STRAPI_URL : ''}${url}`;
		
	} else {
		return `${whenInProduction ? process.env.REACT_APP_STRAPI_URL : ''}${url}`;
	}
}


/* ___________________________________________________________________ Marked */
export const replaceNewLines = (text) => {
	return text.replace(/\n/g, '<br/>');
}