import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../helpers/useFetch';
import styles from '../css/CheckoutCart.module.css';
import {calculateFinalPrice, formatCurrency, prependServerURL} from '../helpers/helper.js';
import { ExclusiveTaxSpan } from './ExclusiveTaxSpan';
import { AuthStateContext } from '../helpers/authContext';

const CheckoutCart = () => {
	const navigate = useNavigate();

	// get items
	const {userData} = useContext(AuthStateContext);
	const url = `${process.env.REACT_APP_STRAPI_URL}/api/cart_items/user_find`;
	const [isLoading, cartItems, errors] = useFetch(url, ['Public']);

	let loadingCartJsx = <></>;
	let totalPriceFinal = 0;
	let totalPriceBase = 0;
	let jsx = loadingCartJsx;

	if(cartItems !== null) {
		
		jsx = cartItems.map(cartItem => {

			const priceObj = calculateFinalPrice(cartItem.basePrice, cartItem.baseDiscount, cartItem.amount, cartItem.priceDropQuantity, cartItem.priceAfterPriceDrop);
			totalPriceFinal += cartItem.amount * priceObj.finalPrice;
			totalPriceBase += cartItem.amount * cartItem.basePrice;

			// Go from json data like: {colorName: 'white', size: 'large'} to {color: 'white, size: 'large'}
			// Just to change how it database data is displayed to user.
			let filteredVariationData = {};
			Object.entries(cartItem.variationData).forEach(entry => {
				const [key, value] = entry;
				let outputKey = key;
				if(key === 'colorName') {
					outputKey = 'color';
				}

				if(value) {
					filteredVariationData[outputKey] = value;
				}
			})

			const priceIsReduced = priceObj.discountApplied || priceObj.priceDropApplied;
			return (
			<li key={cartItem.title + cartItem.colorName + cartItem.size}className={styles.listItem}>
				<article className={styles.article}>
					<div className={styles.imageContainer}>
						<img className={styles.image} src={prependServerURL(cartItem.imagePath)}/>
						<div className={styles.amount}>{cartItem.amount}</div>
					</div>
					<div  className={styles.content}>
						<header className={styles.header}><h3 className={styles.itemTitle}>{cartItem.title}</h3></header>
						<div className={styles.priceContainer}>
							<span className={styles.newAndOldPrice}>
								{priceIsReduced && 
								<>
									<span className={styles.basePriceStrike}>
										{formatCurrency(cartItem.basePrice * cartItem.amount)}
									</span>
									<span>&rarr;</span>
								</>

								}
								<span className={styles.finalPrice}>{formatCurrency(priceObj.finalPrice * cartItem.amount)}</span>
							</span>
							<ExclusiveTaxSpan/>
						</div>
						<ul className={styles.variationDataContainer}>
							{
								Object.entries(filteredVariationData).map(entry => {
									return (
										<li key={entry[0]+entry[1]} className={styles.variationData}>
											<span>{`${entry[0]}: ${entry[1]}`}</span>
										</li>
									);
								})
							}
						</ul>
					</div>
				</article>
			</li>
			);
		})

	


		//No cart items for this user
		if(cartItems.length === 0) {
			navigate('/');
		}

	} else {
		loadingCartJsx = (
			<div>
				<span>Loading...</span>
			</div>
		);
	}

	return (
		<section className={styles.card}>
			<h2 className={styles.title}>Cart</h2>
			<ul className={styles.items}>
				{jsx}
			</ul>
			<div className={styles.total}>
				<span className={styles.totalTitle}>Total</span>
				<span className={styles.totalAmount}>
					{formatCurrency(totalPriceFinal)}
					<ExclusiveTaxSpan/>
					<span className={styles.belowAmount}> + shipping</span>
				</span>
			</div>
		</section>
	)

}
export default CheckoutCart;