import styles from '../css/inputs.module.css';

export const InputFakeRadioButton = ({className}) => {
	return (
		<span className={className}>
			<input disabled checked style={{display: 'none'}} type='checkbox'/>
			<div className={styles.visualCheckbox}/>
		</span>
	);

}