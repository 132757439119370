import styles from '../css/inputs.module.css';

export const InputSelectUpdateField = ({state, name, isRequired, label, clientValue, options, disabledSuffix, error, triggerOnChange, triggerOnSubmit, triggerOnFocus, triggerOnBlur}) => {
	disabledSuffix ??= '';

	const stateData = {
		inactive : {
			className: styles.updateFieldInactive,
			buttonText: 'Edit'
		},
		editing : {
			className: styles.updateFieldEditing,
			buttonText: 'Update'
		},
		updating : {
			className: styles.updateFieldUpdating,
			buttonText: 'Loading...'
		},
		updated : {
			className: styles.updateFieldUpdated,
			buttonText: 'Updated'
		}
	}


	return (
		<div className={`${styles.updateFieldForm} ${error ? styles.hasError : ''} ${stateData[state].className}`}>  
			<label className={styles.label}>{`${label}${isRequired ? '*' : ''}`}</label>
			<form className={`${styles.updateSelectWithStatusButton}`} onSubmit={(e) => {e.preventDefault(); triggerOnSubmit(e)}}>
				<select name={name} value={clientValue} onChange={triggerOnChange} id={name} className={styles.updateSelect}>
					{options.map(option => {
						return <option 
							key={option.value} 
							className={`${styles.option} ${option?.isNoneOption || option.disabled ? styles.disabledOption : ''}`}
							value={option.value}
							disabled={option.disabled}
						>
							{`${option.display}${option.disabled ? disabledSuffix : ''}`}
						</option>;
					})}
				</select>
				<div className={styles.updateSelectFieldButton} htmlFor={name}>{stateData[state].buttonText}</div>
			</form>
			<span className={`${styles.message} ${styles.errorMessage}`}>{error}</span>
		</div>
	);

}