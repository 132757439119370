import GeneralButton from './GeneralButton';
import styles from '../css/OrderConfirmationPage.module.css';
import Page from './Page';

export const OrderConfirmationPage = () => {
	return (
	<Page contentClassName={styles.body}>
		<section className={styles.card}> 
			<h1 className={styles.title}>Order placed</h1>
			<div className={styles.textContent}>

				<p className={styles.intro}>Congratulations! The Belpo team has been notified of your order and will process it as soon as possible.</p>
				<p className={styles.bodyText}>You will receive an e-mail with your order details shortly.</p>
			</div>

			<div className={styles.buttonsWrapper}>
				<GeneralButton colorVariant='orangeFill' size={1} to='/account?tab=history'>View my orders</GeneralButton>
				<GeneralButton size={1} to='/'>Home</GeneralButton>
			</div>
		</section>
	</Page>
	);
}