import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styles from '../css/AccountPage.module.css'
import InputCheckbox from './InputCheckbox';
import InputCheckboxUpdate from './InputCheckboxUpdate';
import InputTextUpdateField from './InputTextUpdateField';
import InputText from './InputText.js';
import GeneralButton from './GeneralButton';
import { dataFromForm } from '../helpers/helper';
import { InputSelectUpdateField } from './InputSelectUpdateField';
import InputSelectControlled from './InputSelectControlled';
import { AuthStateContext } from '../helpers/authContext';


const AccountShippingInfo = ({countryOptions}) => {

	const {userData, jwt, loginWithJwt} = useContext(AuthStateContext);
	const [isSameAsCompanyAddress, setIsSameAsCompanyAddress] = useState(false);

	const [generalFeedback, setGeneralFeedback] = useState({status: 'inactive', message: ''});
	const updateGeneralFeedback = (status, message) => {
		const styleOptions = {
			'inactive': '',
			'loading': styles.generalFeedbackLoading,
			'success': styles.generalFeedbackSuccess,
			'error': styles.generalFeedbackError,

		}
		setGeneralFeedback({status, message, styles: styleOptions[status]});
	};

	const [fieldErrors, setFieldErrors] = useState({});
	const fields = useMemo(() => [
		{
			type:'country',
			name: 'shippingCountry',
			label: 'Shipping country',
			defaultValue: userData.shippingCountry.id,
			error: '',
			isRequired: true,
		},
		{
			type: 'text',
			name: 'shippingCity',
			label: 'Shipping city',
			defaultValue: userData.shippingCity,
			error: '',
			isRequired: true,
		},
		{
			type: 'text',
			name: 'shippingPostalCode',
			label: 'Shipping postal code',
			defaultValue: userData.shippingPostalCode,
			error: '',
			isRequired: true,
		},
		{
			type: 'text',
			name: 'shippingStreetNameAndNumber',
			label: 'Shipping street & number',
			defaultValue: userData.shippingStreetNameAndNumber,
			error: '',
			isRequired: true,
		},
		{
			type: 'text',
			name: 'shippingAddressAddition',
			label: 'Shipping appartement, suite etc.',
			defaultValue: userData.shippingAddressAddition,
			error: '',
			isRequired: false,
		}
	],[userData])


	const [sameAsCompanyAddress, setSameAsCompanyAddress] = useState(userData.isCompanyAddressSameAsShippingAddress); // only client side.
	const [shippingCountry, setShippingCountry] = useState(userData.shippingCountry.id);
	const [dirtyPage, setDirtyPage] = useState(false);
	const setDirtyPageWithEffects = (isDirty) => {
		setDirtyPage(isDirty);
		if(isDirty) {
			setGeneralFeedback('inactive', '');
		}
	}

	const hanldeChangeSameAsCompanyAddress = (e) => {
		setSameAsCompanyAddress(e.target.checked);
		setDirtyPageWithEffects(true);
	}

	const handleOnSubmit = (e) => {
		e.preventDefault();

		// Loading & reset errors
		updateGeneralFeedback('loading','Loading...');
		setFieldErrors({});

		const data = dataFromForm(e.target);
		data['isCompanyAddressSameAsShippingAddress'] = sameAsCompanyAddress;

		let body = {};
		if(!sameAsCompanyAddress) {
			body = {...data};
		} else {
			body['isCompanyAddressSameAsShippingAddress'] = data['isCompanyAddressSameAsShippingAddress'];
		}
		const url = `${process.env.REACT_APP_STRAPI_URL}/api/users-permissions/user_updateShippingDetails`;
		
		
		const asyncUpdateShippingDetails = async(body, url) => {
			const response = await fetch(url, {
				method: 'PUT',
				credentials: 'include',
				headers: {
					"Content-Type" : "application/json",
				},
				body: JSON.stringify(body)
			});

			// 3 possibilities
			// 1. status not ok
			// 2. status ok but has custom errors/feedback from my own validation
			// 3. status ok. Do what is desired

			const responseBody = await response.json();

			if(response.ok) {
				if(responseBody.action === 'success') {
					updateGeneralFeedback('success','Shipping details have been updated');
					setDirtyPageWithEffects(false);
					loginWithJwt();
				} else if (responseBody.action === 'show feedback') {
					setFieldErrors(responseBody.errors);
					updateGeneralFeedback('inactive','');
				}
			} else {
				updateGeneralFeedback('error', responseBody.error.message);
			}
		};
		asyncUpdateShippingDetails(body, url);
	}

	const submit = useRef(null);
	const hidableDiv = useRef(null);
	const [hidableHeight, setHidableHeight] = useState({renderCount: 0, height: 0});
	useEffect(() => {

		const height = hidableDiv.current.offsetHeight;
		const newHeight = `${height + 10}px`;
		const newHeightObj = {renderCount: hidableHeight.renderCount + 1, height: newHeight}; 
		setHidableHeight(newHeightObj);
	},[sameAsCompanyAddress]);

	const handleOnClickSave = (e) => {
		submit.current.click();
	}

	const getFieldError = (field) => {

		// fieldStates ===
		const fieldError = Object.entries(fieldErrors).find(error => {
			const [key] = error;
			return field.name === key;
		});

		return fieldError ? fieldError[1] : '';
	}

	const getHidableHeightStype = () => {
		if(sameAsCompanyAddress) {
			return {maxHeight: '0px'};
		} else {
			return hidableHeight.renderCount > 0 ? {maxHeight: hidableHeight.height} : {height: hidableHeight.height};
		}
	}

	const handleSignalTextInputChange = (e) => {
		if(e.target.name === 'shippingCountry') {
			setShippingCountry(e.target.value);
		}
		setDirtyPageWithEffects(true);
	}

	return (
		<div> 
			<h2 className={styles.panelTitle}>Shipping details</h2>
			<form className={styles.shippingForm} onSubmit={handleOnSubmit}>
				<InputCheckbox defaultValue={sameAsCompanyAddress} name="isCompanyAddressSameAsShippingAddress" onChange={hanldeChangeSameAsCompanyAddress}>Shipping address is same as company address</InputCheckbox>
				<div style={getHidableHeightStype()} className={`${styles.hidableShippingFields} ${sameAsCompanyAddress ? styles.hidableShippingFieldsHidden : ''}`}>
					<div ref={hidableDiv}>

						{fields.map((field, index) => { 
							if(field.type === 'text') {

								return <InputText key={field.name}
								name={field.name} 
								error={getFieldError(field)}
								defaultValue={field.defaultValue}
								isRequired={field.isRequired}
								disabled={sameAsCompanyAddress}
								signalOnChange={handleSignalTextInputChange}
								>{field.label}</InputText>
							} else if(field.type === 'country') {
								return <InputSelectControlled key={field.name} label='Shipping Country' selectedOption={shippingCountry} name='shippingCountry'  options={countryOptions} error={getFieldError(field)} onChange={handleSignalTextInputChange} required/>;
							}
						}
						)}
					</div>
				</div>
				<div className={`${styles.submitWrapper} ${fieldErrors?.length > 0 ? styles.submitError : ''}`}>
					<GeneralButton disabled={!dirtyPage} onClick={handleOnClickSave}>{dirtyPage ? 'Save' : 'Saved'}</GeneralButton>
					{generalFeedback.message !== '' && 
					<span 
						className={`${styles.generalFeedback} ${generalFeedback.styles}`}>
						{generalFeedback.message}
					</span>
					}
				</div>
				<input type='submit' style={{display:'none'}} ref={submit}></input>
			</form>
		</div>
	)
}

export default AccountShippingInfo;