import { useContext, useRef, useState } from 'react';
import {Link} from 'react-router-dom';

import styles from '../css/SignUpPage.module.css';

import GeneralButton from './GeneralButton';
import InputText from './InputText';
import Page from './Page';
import { AuthStateContext } from '../helpers/authContext';

const SignInPage = ({loginData, loginFunc}) => {
		
	const {login} = useContext(AuthStateContext);

	const params = new URLSearchParams(window.location.search);
	console.log({params});
	const submit = useRef();
	const fieldNames = ['Email', 'Password']; // not shared with signup
	const [errors, setErrors] = useState({});
	const dataFromForm = $form => {
		const formData = new FormData($form);
		const data = Object.fromEntries(formData);
		return data;	// returns object of objects that are name/value pairs
	}

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		const data = dataFromForm(e.currentTarget);
		const result = await login(data[fieldNames[0]], data[fieldNames[1]]);

		if(!result) 
		return;

		const newErrors = {...result.errors};
		result.forEach(errorObj => {
			if(/^identifier/.test(errorObj.message)) {
				newErrors[fieldNames[0]] = 'Is required';
			} else if ( /password is a required field/.test(errorObj.message)) {
				newErrors[fieldNames[1]] = 'Is required';
			} else {
				if(errorObj.message.includes('blocked')) {
					newErrors[fieldNames[0]] = 'Awaiting approval from Belpo. You will be notified within 48h.';
				} else {
					newErrors[fieldNames[0]] = 'Incorrect email or password';
				}
			}
		})
		setErrors(newErrors);
	}

	const getErrorJsx = (fieldName) => {
		if(errors.hasOwnProperty(fieldName)) {
			return errors[fieldName]
		} else return '';
	}

	const removeError = (fieldName) => {
		const newErrors = {...errors};
		delete newErrors[fieldName];
		setErrors(newErrors);
	}



	return (
		<Page bHasNavBar={false} pageWidth='450px' className={styles.cardWrapper}>
			<div className={styles.card}>
			<header className={styles.header}>
				<Link className={styles.left} to="/">
					<span className={styles.belpo}>Belpo</span>
					<span className={styles.b2b}>B2b portal</span>
				</Link>
				<Link to='/signup'className={styles.right}>
					Don't have<br/> an account? <br/>
					<strong>Sign up</strong>
				</Link>
				{
					params.has('from-confirmation-email') && 
					<div className={styles.registered}>You've been registered</div>
				}
				<h1 className={styles.title}>Sign in</h1>
			</header>


			<form onSubmit={handleOnSubmit}>

				<InputText defaultValue='' type='email' name={fieldNames[0]}  error={getErrorJsx(fieldNames[0])} onFocus={() => removeError(fieldNames[0]) }>Email</InputText>
				<InputText defaultValue='' type='password' name={fieldNames[1]} error={getErrorJsx(fieldNames[1])} onFocus={() => { removeError(fieldNames[0]); removeError(fieldNames[1])} } 
					componentUnderField={
						<Link to='/forgotpassword'><span className={styles.forgotPassword}>Forgot password?</span></Link>
					}
				>Password</InputText>

				<GeneralButton size={1} onClick={() => submit.current.click()}>Sign in</GeneralButton>
				<input ref={submit} style={{display: 'none'}} type='submit' value='sign in'/>
			</form>
			</div>
		</Page>
		
		);

	};
	
	export default SignInPage;