import Page from './Page'
import styles from '../css/ForgotPasswordPage.module.css'
import InputText from './InputText'
import { Link } from 'react-router-dom'
import GeneralButton from './GeneralButton'
import { useRef, useState } from 'react'
import { dataFromForm } from '../helpers/helper'
export const ForgotPasswordPage = ({}) => {

	const [resetHasBeenSent, setResetHasBeenSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const submitRef = useRef(null)
	const triggerReset = async (e) => {
		e.preventDefault();
		setIsLoading(true)
		const data = dataFromForm(e.target);
		const body = { email: data.email}
		const jsonBody =  JSON.stringify(body)
		console.log({body});
		console.log({jsonBody});
		const url = `${process.env.REACT_APP_STRAPI_URL}/api/auth/forgot-password`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: jsonBody
		})

		setIsLoading(false)
		const result = await response.json();
		console.log('response')
		console.log(response)
		console.log('result')
		console.log(result)
		setResetHasBeenSent(true);
	}

	console.log('from forgot password')
	return (
		<Page bHasNavBar={false} pageWidth='450px' contentClassName={styles.page}>
			<header className={styles.header}>
				<div className={styles.left}>
					<span className={styles.belpo}>Belpo</span>
					<span className={styles.b2b}>B2b portal</span>
				</div>
				<Link to='/signin'className={styles.right}>
					<span>Back to Sign In</span>
				</Link>
				<h1 className={styles.title}>Forgot Password</h1>
			</header>
			{ resetHasBeenSent === false && <form onSubmit={triggerReset}>
				<span className={styles.paragraph}>Enter your email address and we will send you a link to reset your password.</span>
				<InputText type='email' name='email'>Email Adress</InputText>
				<GeneralButton onClick={() => submitRef.current.click()} size={1} isLoading={isLoading}>Reset</GeneralButton>
				<input ref={submitRef} type='submit' style={{display:'none'}}></input>
			</form>
			}
			{resetHasBeenSent === true && <div className={styles.stage2}>
				<span className={styles.stage2__hands}>👏</span>
				<span className={styles.stage2__mainText}>Email has been sent.</span>
				<span className={styles.stage2__subText}>Please verify your account via the link in the e-mail and reset your password.</span>
				<Link to='/signin'><span className={styles.stage2__back}>Back</span></Link>
			
			</div>}
		</Page>
	)
}